import React, { useState } from 'react'
import NewProducts from './newProducts'

const ProductsInStore = () => {

    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = ["New Products", "Featured Products", "On Sale Products"]

    //tabComponents
    const tabComponents = [<NewProducts />, <NewProducts />, <NewProducts />]

    return (
        <>
            <div className='text-deepBlue'>
                <p className='text-[30px] font-[600] text-center'>Our Products In Store</p>

                <p className='text-[16px] font-[400] text-center mt-[16px]'>Top view in this week</p>

                <div className='mt-[32px] flex gap-[5px] items-center justify-center flex-wrap'>
                    {
                        tabNames.map((tab, i) => (
                            <div key={i} className={`${activeTab === i ? 'bg-defaultBlue text-white' : 'bg-white text-deepBlue'} px-[30px] py-[7px] rounded-[30px] shadow-sm cursor-pointer`} onClick={() => setActiveTab(i)}>
                                <p className='lg:text-[16px] text-[12px] font-[400]'>{tab}</p>
                            </div>
                        ))
                    }
                </div>

                <div className="mt-[48px] w-full">
                    {
                        tabComponents[activeTab]
                    }
                </div>
            </div>
        </>
    )
}

export default ProductsInStore
