import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { HiOutlineInformationCircle, HiPlus } from 'react-icons/hi2'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import DepositModal from './depositModal'

const BalanceCard = () => {

    const [isBusy, setIsBusy] = useState(true)
    const [walletData, setWalletData] = useState<any>(null)
    const [reFetch, setReFetch] = useState(false)

    //openDeposit
    const [openDeposit, setOpenDeposit] = useState(false)
    const handleOpenDeposit = () => {
        setOpenDeposit(true)
    }
    const handleCloseDeposit = () => {
        setOpenDeposit(false)
        setReFetch(!reFetch)
    }


    //component onMount
    useEffect(() => {
        getWalletData()
    }, [reFetch])

    //getWalletData
    const getWalletData = async () => {
        setIsBusy(true)

        try {
            const response = await BaseService.get_api(`${global_variables.get_user_wallet}/65d4b85e9ae65ff72c90900e`)
            // console.log("userWalletRes:", response?.data)
            setWalletData(response?.data?.payload)
            setIsBusy(false)
        } catch (error) {
            console.log("userWalletErr:", error)
        }
    }


    return (
        <>
            <div className="w-full rounded-[10px] bg-gradient-to-r from-purple-300 to-defaultBlue p-5 shadow-sm text-white">
                <p className='font-semibold text-[42px]'>
                    {
                        isBusy ?
                            <div className='h-[60px] w-[200px] rounded bg-black bg-opacity-[20%] animate-pulse'></div>
                            :
                            walletData && walletData?.countryData?.currencyCode + " " + walletData?.amount?.toFixed(2)
                    }
                </p>

                {
                    isBusy ? <div className='h-3 w-[100px] rounded bg-black bg-opacity-[20%] animate-pulse mt-3'></div>
                        :
                        <p>Your Balance</p>
                }

                <div className="flex justify-between items-center mt-5">
                    {
                        isBusy ?
                            <div className="flex gap-5 items-center">
                                <div className='h-[30px] w-[120px] rounded-md bg-black bg-opacity-[20%]'></div>

                                <div className='h-[30px] w-[120px] rounded-md bg-black bg-opacity-[20%]'></div>
                            </div>
                            :
                            <div className="flex gap-5 items-center">
                                <button className='flex gap-1 items-center px-4 py-1 bg-white shadow-sm rounded-md text-black' onClick={handleOpenDeposit}>
                                    <HiPlus className='h-4 w-4' />
                                    <p>Deposit</p>
                                </button>

                                {/* <button className='flex gap-1 items-center px-4 py-1 bg-white shadow-sm rounded-md text-black'>
                                    <HiArrowDown className='h-4 w-4' />
                                    <p>Withdraw</p>
                                </button> */}
                            </div>
                    }

                    {
                        isBusy ?
                            <div className='h-10 w-10 rounded-md bg-black bg-opacity-[20%]'></div>
                            :
                            <Tooltip title="Save time and effort during checkout by using your personalized Robocentre Wallet. Enjoy swift and secure transactions across all our platforms." placement="topRight">
                                <button className='h-10 w-10 flex justify-center items-center bg-white shadow-sm rounded-md text-black'>
                                    <HiOutlineInformationCircle className='h-5 w-5' />
                                </button>
                            </Tooltip>
                    }
                </div>
            </div>


            {/* deposit modal */}
            {
                openDeposit && <DepositModal isOpened={openDeposit} handleClose={handleCloseDeposit} handleReFetch={() => setReFetch(!reFetch)} />
            }
        </>
    )
}

export default BalanceCard
