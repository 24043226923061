import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { MAPS_API } from "../../helpers/constants";

interface selectProps {
    onChange: (data: any) => void
    placeholder?: string
    disabled?: boolean
    defaultInputValue?: string
}

const LocationSelector = ({ onChange, placeholder, disabled, defaultInputValue }: selectProps) => {


    return (
        <>
            <GooglePlacesAutocomplete
                apiKey={MAPS_API}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ["gh"],
                    },
                }}
                selectProps={{
                    className: "border-none",
                    placeholder: placeholder,
                    isClearable: true,
                    isSearchable: true,
                    onChange: (selected: any) => onChange(selected),
                    isDisabled: disabled,
                    defaultInputValue: defaultInputValue
                }}
            />
        </>
    )
}

export default LocationSelector
