export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
// export const BASE_URL = process.env.REACT_APP_PROD_URL;
export const CRYPTO_KEY = process.env.REAT_APP_CRYPTO_KEY;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL as string;
export const MAPS_API = process.env.REACT_APP_MAPS_API as string;
export const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;

export const COOKIES_TOKEN_NAME = "access_token";
export const COOKIES_COUNTRY = "selected_country";
export const COOKIES_USER_DATA = "user_data";
export const COOKIES_USER_VERIFIED = "verified";
