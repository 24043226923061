import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import SignIn from './signIn'
import SignUp from './signUp'
import { IoCloseCircleOutline } from 'react-icons/io5'


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    tabNumber: number
}


const AuthenticationModal = ({ isOpened, handleClose, tabNumber }: modalProps) => {

    const [activeTab, setActiveTab] = useState(0)

    //onload
    useEffect(() => {
        setActiveTab(tabNumber);
    }, [tabNumber])

    //tabNames
    const tabNames = ["Sign up", "Sign in"]

    //tabComponents
    const tabComponents = [
        <SignUp handleClose={handleClose} />,
        <SignIn handleClose={handleClose} />
    ]

    return (
        <>
            <Modal width={474} open={isOpened} onCancel={handleClose} keyboard={false} maskClosable={false} footer={null} closeIcon={false} centered>
                <div className="w-full 2xl:h-[450px] xl:h-[450px] lg:h-[450px] md:h-[350px] overflow-y-auto">
                    <div className="px-[44px]">
                        <div className="flex justify-end text-gray-500 mb-5">
                            <IoCloseCircleOutline className="h-5 w-5 cursor-pointer" onClick={handleClose} />
                        </div>
                        <div className="p-[8px] rounded-[16px] bg-[#F0F0FD] flex gap-[8px] items-center w-full justify-center">
                            {
                                tabNames.map((tab, i) => (
                                    <div key={i} className={`${activeTab === i ? 'rounded-[16px] bg-white' : ''} px-[30px] py-[8px] w-fit cursor-pointer`} onClick={() => setActiveTab(i)}>
                                        <p className='2xl:text-[30px] xl:text-[30px] lg:text-[30px] md:text-[30px] text-[14px] font-[600] text-defaultBlue'>{tab}</p>
                                    </div>
                                ))
                            }
                        </div>

                        {/* components */}
                        <div className="mt-[24px]">
                            {tabComponents[activeTab]}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AuthenticationModal
