import React, { useEffect, useState } from 'react'
import { TransactionPayload } from '../../../models/transactions.model'
import BaseService from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { Empty, Tag } from 'antd'
import DataTable from 'react-data-table-component'
import moment from 'moment'

const AllTransactions = () => {

    const [isFetching, setIsFetching] = useState(true)
    const [transactionData, setTransactionData] = useState([])
    const [limit, setLimit] = useState(10)
    const [skip, setSkip] = useState(0)
    const [total, setTotal] = useState(0)

    //on component mount
    useEffect(() => {
        const getTransactions = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api(`${global_variables.get_user_transactions}?limit=${limit}&skip=${skip}&sort={"timestamp": -1}`)
                console.log("transRes:", response?.data)
                setTransactionData(response?.data?.payload)
                setTotal(response?.data?.total)
                setIsFetching(false)
            } catch (error) {
                console.log("transErr:", error)
            }
        }

        getTransactions()
    }, [limit, skip])

    //getStatusTag
    const getStatusTag = (status: string) => {
        switch (status) {
            case 'Pending':
                return <Tag color="warning">Pending</Tag>;
            case 'Successful':
                return <Tag color="success">Successful</Tag>;
            case 'Failed':
                return <Tag color="error">Failed</Tag>;
            case 'Cancel':
                return <Tag color="error">Cancel</Tag>;
            default:
                return <Tag color="default">Unknown</Tag>
        }
    };


    // Define the columns for the table
    const columns: any = [
        {
            name: "",
            cell: (row: TransactionPayload) => <img className='h-10 w-10 object-cover rounded-full' src={row?.userData?.image} alt={row?.userData?.firstName} />,
            width: "10%",
        },
        {
            name: "Full Name",
            cell: (row: TransactionPayload) => row?.userData?.fullName,
        },
        {
            name: "Action",
            cell: (row: TransactionPayload) => row?.action === "deposit" ? <Tag color='blue'>Deposit</Tag> : row?.action === "debit" ? <Tag color="magenta">Debit</Tag> : <Tag color='orange'>Credit</Tag>,
        },
        {
            name: "Amount",
            selector: (row: TransactionPayload) => row?.action === "deposit" ? row?.countryData?.currencyCode + " " + row?.deposit.toFixed(2) : row?.action === "debit" ? row?.countryData?.currencyCode + " " + row?.debit.toFixed(2) : row?.countryData?.currencyCode + " " + row?.credit.toFixed(2),
        },
        {
            name: "Status",
            selector: (row: TransactionPayload) => getStatusTag(row?.status),
        },
        {
            name: "Timestamp",
            cell: (row: TransactionPayload) => row?.timestamp ? moment(row?.timestamp).format("Do MMM YYYY - hh:mm A") : "N/A",
        }
    ];


    // pagination start
    const handleRowChange = (a: any) => {
        setLimit(a);
    }

    const pageChange = (a: any) => {
        if (a === 1) {
            setSkip(0)
        } else {
            const newSkip = (a * limit) - limit;
            setSkip(newSkip);
        }
    }
    // pagination end


    return (
        <>
            <DataTable
                columns={columns}
                data={transactionData}
                pagination
                paginationServer
                noDataComponent={<div>
                    <Empty description="There are no records to display" />
                </div>}
                paginationTotalRows={total}
                progressPending={isFetching}
                onChangeRowsPerPage={handleRowChange}
                onChangePage={pageChange}
                responsive
                highlightOnHover
            //   onRowClicked={(data: any) => handleViewDetails(data)}
            />
        </>
    )
}

export default AllTransactions
