import React from 'react'

const SubscriptionCard = () => {
    return (
        <>
            <div className="xl:px-[180px] lg:px-[180px] lg:md-[100px] px-[25px] lg:py-[70px] py-[20px] rounded-[30px] bg-white shadow-md xl:w-[880px] lg:w-[880px] md:w-[480px] w-full flex mr-auto ml-auto my-5 lg:my-0">
                <div className='lg:flex lg:flex-col items-center justify-center h-full w-full'>
                    <p className='xl:text-[52px] lg:text-[52px] md:text-[22px] text-[30px] font-[600] text-deepBlue mb-0 text-center'>Get 10% Off</p>
                    <p className='xl:text-[52px] lg:text-[52px] md:text-[22px] text-[30px] font-[600] text-deepBlue mt-0 text-center'> On Your First Order</p>

                    <p className='xl:text-[21px] lg:text-[21px] md:text-[18px] text-[14px] font-[400] text-deepBlue mt-3 text-center'>
                        By subscribing to newsletter
                    </p>

                    <div className='mt-[50px] xl:relative lg:relative md:relative flex flex-col'>
                        <input className='h-[60px] lg:w-[530px] rounded-[40px] focus:outline-none bg-white shadow-lg pl-5 border border-defaultBlue' placeholder='Your email' type="email" />

                        <button className='mt-5 lg:mt-0 px-[28px] h-[45px] bg-defaultBlue text-white rounded-[30px] xl:absolute lg:absolute md:absolute xl:right-[5px] lg:right-[5px] md:right-[5px] xl:top-[7px] lg:top-[7px] md:bottom-[7px]'>
                            <p className='text-[16px] font-[500]'>SUBMIT</p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionCard
