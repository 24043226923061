import BaseService from "../../helpers/baseServices";

export const InitiatePayment = async (pay_data: any) => {
  try {
    const response = await BaseService.post_api(
      `/robocentre/transaction/momo-deposit-direct`,
      pay_data
    );
    return response?.data?.payload;
  } catch (error) {
    return error;
  }
};

export const VerifyPaymentOTP = async (otp_data: any) => {
  try {
    const response = await BaseService.post_api(
      `/robocentre/transaction/otp-verification-direct`,
      otp_data
    );
    return response?.data?.payload;
  } catch (error) {
    return error;
  }
};

export const VerifyPayment = async (ref_data: any) => {
  if (ref_data) {
    const maxAttempts = 20;

    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        console.log(`Verification Attempt ${attempt}`);

        const verify_body = {
          reference: ref_data,
        };

        const response = await BaseService.post_api(
          `/robocentre/transaction/manual-verification-direct`,
          verify_body
        );

        const statusCode = response?.data?.payload?.transaction?.status;

        if (statusCode === "Successful" || statusCode === "Failed") {
          return response?.data;
        }

        // Wait for a while before the next attempt
        await new Promise((resolve) => setTimeout(resolve, 15000)); // 15 seconds
      } catch (error) {
        // Handle error if needed
        return error;
      }
    }

    // If not successful after 10 attempts, return an error or handle it accordingly
    return { error: "Max attempts reached, transaction status not updated." };
  }
};
