import React, { useEffect, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import BaseService from '../../../../helpers/baseServices'
import { global_variables } from '../../../../helpers/globalVariables'
import { Empty, message, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';


const WishlistItems = () => {

    const tempData = Array.from({ length: 10 })
    const [isFetching, setIsFetching] = useState(true)
    const [isBusy, setIsBusy] = useState(false)
    const [itemsList, setItemsList] = useState([])
    const [selected, setSelected] = useState<any>(null)
    const [reFetch, setReFetch] = useState(false)

    //getAllWishlistItems
    const getAllWishlistItems = async () => {
        setIsFetching(true)

        try {
            const response = await BaseService.get_api(`${global_variables.get_all_wishlist}`)
            console.log("getWishlistRes", response?.data)
            setItemsList(response?.data?.payload)
            setIsFetching(false)
        } catch (error) {
            console.log("getWishlistErr", error)
        }
    }


    //onload
    useEffect(() => {
        getAllWishlistItems()
    }, [reFetch])


    //handleRemoveWishlist
    const handleRemoveWishlist = async (data: any) => {
        setSelected(data)

        setIsBusy(true)

        try {
            const response = await BaseService.delete_api(`${global_variables.add_to_wishlist}/${data?._id}`)
            console.log("delWishRes:", response?.data)
            message.success(`${data?.name} was removed from wishlist`)
            setReFetch(!reFetch)
            setIsBusy(false)
        } catch (error: any) {
            console.log("delWishErr:", error)
            message.error(error?.response?.data?.error || "Could not remove product from wishlist")
            setIsBusy(false)
        }
    }

    return (
        <>
            <div className="h-full w-full px-[24px]">
                <p className='text-[30px] font-[600] text-deepBlue'>Orders</p>

                <div className="w-full mt-5">
                    {/* heading */}
                    <div className='w-full grid grid-cols-5 text-deepBlue'>
                        <div className="w-full col-span-3">
                            <p className='text-[16px] font-[600]'>Product</p>
                        </div>

                        <div className="w-full">
                            <p className='text-[16px] font-[600]'>Price</p>
                        </div>

                        <div className="w-full"></div>
                    </div>

                    <hr className='border-[#E0E0FB]' />

                    {/* list */}
                    <div className="mt-2">
                        {
                            isFetching ?
                                <>
                                    {
                                        tempData.map((item, i) => (
                                            <div key={i} className='w-full grid grid-cols-5 gap-5 text-deepBlue items-center border-b border-b-[#E0E0FB] pb-2 mb-5 animate-pulse'>
                                                <div className="w-full col-span-3 flex gap-5">
                                                    <div className='w-[114px] '>
                                                        <div className='h-[70px] w-full rounded-[5px] bg-gray-300' />
                                                    </div>

                                                    <div>
                                                        <div className="w-full 2xl:w-[120px] xl:w-[120px] lg:w-[120px] md:w-[120px] rounded h-4 bg-gray-300"></div>
                                                        <div className="w-full 2xl:w-[180px] xl:w-[180px] lg:w-[180px] md:w-[180px] rounded h-2 bg-gray-300 mt-2"></div>
                                                        <div className="w-full 2xl:w-[180px] xl:w-[180px] lg:w-[180px] md:w-[180px] rounded h-2 bg-gray-300 mt-1"></div>
                                                    </div>
                                                </div>

                                                <div className="w-full">
                                                    <div className="w-full 2xl:w-[100px] xl:w-[100px] lg:w-[100px] md:w-[100px] rounded h-4 bg-gray-300"></div>
                                                </div>

                                                <div className="w-full">
                                                    <div className='h-5 w-5 bg-gray-300 rounded' />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                                : itemsList?.length === 0 || !itemsList ?
                                    <>
                                        <div className="mt-10">
                                            <Empty description="No items in wishlist" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            itemsList.map((item: any, i: number) => (
                                                <div key={i} className='w-full grid grid-cols-5 gap-5 text-deepBlue items-center border-b border-b-[#E0E0FB] pb-2 mb-5'>
                                                    <div className="w-full col-span-3 flex gap-5">
                                                        <div className='w-[114px]'>
                                                            <img className='h-[70px] w-full rounded-[5px] object-cover' src={item?.images?.[0] || ""} alt="" />
                                                        </div>

                                                        <div>
                                                            <p className='text-[21px] font-[600]'>{item?.name}</p>
                                                            {/* <p className='text-[16px] font-[400] line-clamp-2'>{item?.description}</p> */}
                                                        </div>
                                                    </div>

                                                    <div className="w-full">
                                                        <p className='text-[21px] font-[600]'>GHS {item?.salePrice}</p>
                                                    </div>

                                                    <div className="w-full">
                                                        {
                                                            item?.name === selected?.name && isBusy ?
                                                                <Spin indicator={<LoadingOutlined className='text-defaultBlue' style={{ fontSize: 24 }} spin />} />
                                                                :
                                                                <BiTrash className='h-5 w-5 cursor-pointer text-[#6E6C8E]' onClick={() => handleRemoveWishlist(item)} />
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WishlistItems
