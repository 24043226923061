export const StorageBox = {
  getAccessToken: () => {
    const theToken = localStorage.getItem("access_token") ?? null;
    return theToken;
  },
  saveAccessToken: (token: any) => {
    localStorage.setItem("access_token", token);
  },
  saveUserData: (data: any) => {
    localStorage.setItem("userData", JSON.stringify(data));
  },
  retrieveUserData: () => {
    const theUserdata: any = localStorage.getItem("userData") ?? null;
    return JSON.parse(theUserdata);
  },
  saveCountry: (data: any) => {
    localStorage.setItem("selected_country", JSON.stringify(data));
  },
  getSavedCountry: () => {
    const selectedCountry: any =
      localStorage.getItem("selected_country") ?? null;
    return JSON.parse(selectedCountry);
  },
  clearStorage: () => {
    localStorage.clear();
  },
};
