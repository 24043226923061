import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ShopHome from "./pages/shop/shopHome";
import ProductDetails from "./pages/shop/productDetails";
import CartPage from "./pages/shop/cartPage";
import CheckoutPage from "./pages/shop/checkout/checkoutPage";
import InvoicePage from "./pages/invoice/invoicePage";
import ProductListings from "./pages/shop/productListings";
import { GeneralContextProvider } from "./context/generalContext";
import MyWallet from "./pages/wallet/myWallet";
import PaymentPage from "./pages/shop/checkout/paymentPage";
import VerifyMomoPayment from "./pages/shop/checkout/verifyMomoPayment";
import AuthOtpVerify from "./pages/authentication/authOtpVerify";
import MyAccount from "./pages/myAccount/myAccount";


function App() {
  return (
    <>
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<h4>404 Page not fouond!</h4>} />
            <Route path="/" element={<ShopHome />} />
            <Route path="/verify-account" element={<AuthOtpVerify />} />
            <Route path="/shop" element={<ProductListings />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/checkout/:id" element={<CheckoutPage />} />
            <Route path="/payment/:id" element={<PaymentPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/invoice/:id" element={<InvoicePage />} />
            <Route path="/wallet" element={<MyWallet />} />
            <Route path="/verify-payment/:id" element={<VerifyMomoPayment />} />
            <Route path="/my-account" element={<MyAccount />} />
          </Routes>
        </Router>
      </GeneralContextProvider>
    </>
  );
}

export default App;
