import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'

interface theProps {
    productImages: any
    isBusy: boolean
}

const ProductImageGrid = ({ productImages, isBusy }: theProps) => {

    const [currImage, setCurrImage] = useState<any>("")
    const [allImages, setAllImages] = useState([])

    //onload
    useEffect(() => {
        if (productImages) {
            setCurrImage(productImages[0])
            setAllImages(productImages)
        }
    }, [productImages])

    return (
        <>
            <div className='flex gap-[8px] h-full'>
                {/* list */}
                <div className='flex flex-col gap-[10px]'>
                    {
                        isBusy ?
                            ["", "", "", ""].map((item: any, i: number) => (
                                <>
                                    <Skeleton.Image active />
                                </>
                            ))
                            :
                            allImages.map((item: any, i: number) => (
                                <div key={i} className='cursor-pointer' onClick={() => setCurrImage(item)}>
                                    <img className='h-[100px] w-[120px] object-cover rounded-[10px]' src={item} alt='' />
                                </div>
                            ))
                    }
                </div>

                {/* big view */}
                {
                    isBusy ?
                        <div className='rounded-[10px] w-full h-auto bg-gray-300 animate-pulse shadow-sm'></div>
                        :
                        <div className='rounded-[10px] w-full h-auto bg-gradient-to-b from-white to-gray-100 shadow-sm'>
                            <img className='rounded-[10px] h-full w-full object-cover' src={currImage} alt="bigImg" />
                        </div>
                }
            </div>
        </>
    )
}

export default ProductImageGrid
