import React from 'react'
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { FaAngleDown, FaPowerOff } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { IoWalletOutline } from "react-icons/io5";
import { PerformLogout } from '../../helpers/utils';
import { PiUserCircleLight } from "react-icons/pi";
import { useCart } from '../../context/generalContext';


interface theProps {
    reFetch: boolean
}


const UserProfile = ({ reFetch }: theProps) => {

    const navigate = useNavigate()
    const { userData } = useCart()



    //dropdown menu
    const items: MenuProps['items'] = [
        {
            label: <button className='flex gap-2 items-center w-full' onClick={() => navigate("/my-account")}>
                <PiUserCircleLight className='h-5 w-5' />
                <p>My Account</p>
            </button>,
            key: '1',
        },
        {
            label: <button className='flex gap-2 items-center w-full' onClick={() => navigate("/wallet")}>
                <IoWalletOutline className='h-5 w-5' />
                <p>My Wallet</p>
            </button>,
            key: '2',
        },
        {
            type: 'divider',
        },
        {
            label: <button className='flex gap-2 items-center text-red-500 w-full' onClick={PerformLogout}>
                <FaPowerOff />
                Logout
            </button>,
            key: '3',
        },
    ];


    return (
        <>
            <Dropdown className='cursor-pointer flex items-center gap-1' menu={{ items }} trigger={['click']}>
                <button onClick={(e) => e.preventDefault()}>
                    <img className='h-[30px] w-[30px] object-cover rounded-full cursor-pointer' src={userData?.image || "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"} alt={userData?.firstName} />
                    <FaAngleDown className='h-3 w-3' />
                </button>
            </Dropdown>
        </>
    )
}

export default UserProfile
