import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Lottie from "lottie-react";
import otpAnimation from "../../../animations/otp-animation.json"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { VerifyPayment } from '../../../functions/directPayment/directPayments';
import ShowAlert from '../../../components/alerts/all_alerts';

const VerifyMomoPayment = () => {

    const { id } = useParams()


    //onload
    useEffect(() => {

        if (id) {
            console.log(`Reference is: ${id}`)

            const runVerification = async () => {
                try {
                    const verifyRes = await VerifyPayment(id)
                    console.log("verifyingRes:", verifyRes)
                    if (verifyRes?.payload?.transaction?.status === 'Successful') {
                        ShowAlert.success_alert("Payment Successful!", "Congratulations on completing your purchase.", "View Invoice").then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = `/invoice/${verifyRes?.payload?.transaction?.salesId}`
                            }
                        })
                    } else if (verifyRes?.payload?.transaction?.status === 'Failed') {
                        ShowAlert.error_alert("Transaction Failed!", "Transaction failed. Please try again", "Retry").then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = "/cart"
                            }
                        })
                    } else {
                        ShowAlert.error_alert("Transaction Failed!", "Transaction failed. Please try again", "Retry").then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = "/cart"
                            }
                        })
                    }
                } catch (error: any) {
                    console.log("verifyingErr:", error)
                    ShowAlert.error_alert("Transaction Failed!", error?.response?.data?.error, "Retry").then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/cart"
                        }
                    })
                }
            }

            runVerification()
        }

    }, [id])


    return (
        <>
            <div className="h-screen overflow-hidden bg-defaultBlue">
                <div className="h-full overflow-y-auto flex flex-col justify-center items-center p-5">
                    <div className="xl:w-[600px] lg:w-[600px] md:w-[600px] w-full bg-white p-5 shadow-md rounded-lg">
                        <div className='w-full text-center mt-5'>
                            <p className='text-[30px] font-[600] text-deepBlue'>Payment Verification</p>
                        </div>

                        <hr className='my-2' />

                        <div className="w-full flex justify-center mt-5">
                            <p className='text-lg text-center'>Please complete the authorisation process by inputting your PIN on your mobile device</p>
                        </div>

                        <div className="px-5 md:px-10 lg:px-[93px] xl:px-[93px] w-full flex flex-col justify-center items-center">
                            <div className="w-[300px] flex ml-auto mr-auto">
                                <Lottie animationData={otpAnimation} loop={true} />
                            </div>

                            <div className="w-full flex justify-center mt-5">
                                <p>Verifying your payment please wait...</p>
                            </div>

                            <div className="my-5 flex justify-center">
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 42, color: "#000" }} spin />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VerifyMomoPayment
