import React, { useEffect, useState } from 'react'
import electornIcon from "../../images/icons/electronIcon.svg"
import { GetProductCategories } from '../../functions/products'

const ProductCategoryComponent = () => {

    const tempItems = Array.from({ length: 8 })
    const [isBusy, setIsBusy] = useState(false)
    const [allProdCats, setAllProdCats] = useState([])

    //onload
    useEffect(() => {
        const getProductCats = async () => {
            setIsBusy(true)

            try {
                const response: any = await GetProductCategories();
                console.log(response);
                setAllProdCats(response?.data?.payload)
                setIsBusy(false)
            } catch (error) {
                console.error('Error fetching product categories:', error);
            }
        }

        getProductCats()
    }, [])


    return (
        <>
            <div className="h-[413px] bg-white shadow-sm py-4 rounded-[16px] mb-5 lg:mb-0">
                <div className="px-4 py-2">
                    <p className='font-[600] text-defaultBlue'>Categories:</p>
                </div>
                {
                    isBusy ?
                        tempItems.map((item: any, i: number) => (
                            <div key={i} className='flex gap-5 items-center px-4 py-2 w-full animate-pulse'>
                                <div className="h-6 w-6 rounded-full bg-gray-300"></div>
                                <div className="lg:w-[300px] w-full h-3 bg-gray-300 rounded"></div>
                            </div>
                        ))
                        :
                        allProdCats.map((item: any, i: number) => (
                            <button key={i} className='flex gap-5 items-center px-4 py-2 w-full hover:bg-gray-50'>
                                <img src={electornIcon} alt="elec" />
                                <p className='text-[16px] font-[400] text-deepBlue'>{item?.name}</p>
                            </button>
                        ))
                }
            </div>
        </>
    )
}

export default ProductCategoryComponent
