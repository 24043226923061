import React, { useEffect, useState } from 'react'
import SearchBar from '../../components/shared/searchBar'
import CustomeNavbar from '../../components/layout/customeNavbar'
import SingleProduct from '../../components/shared/singleProduct'
import CustomFooter from '../../components/layout/customFooter'
import { Breadcrumb, Pagination } from 'antd'
import mingoRobot from "../../images/mingoRobot1.png"
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import SingleProductShimmer from '../../components/shared/singleProductShimmer'

const ProductListings = () => {

    const tempProducts = Array.from({ length: 12 })
    const [isBusy, setIsBusy] = useState(true)
    const [allNewProducts, setAllNewProducts] = useState([])
    const [limit, setLimit] = useState(12)
    const [skip, setSkip] = useState(0)
    const [total, setTotal] = useState(0)

    //getNewProducts
    const getNewProducts = async (lim: number, sk: number) => {
        try {
            const response = await BaseService.get_api(`${global_variables.all_products}?limit=${lim}&skip=${sk}`)
            // console.log(response?.data)
            setAllNewProducts(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsBusy(false)
        } catch (error) {
            console.log("Error fetching new products:", error)
        }
    }

    // onload
    useEffect(() => {

        getNewProducts(limit, skip)

    }, [limit, skip])

    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* navbar */}
                <CustomeNavbar />

                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='flex justify-center w-full px-5 lg:px-0'>
                        <SearchBar />
                    </div>

                    {/* banner */}
                    <div className="w-full bg-[#3FB6F3] text-white h-[297px] lg:px-[140px] px-5 flex flex-col justify-center">
                        <div className="lg:grid lg:grid-cols-2 gap-[100px] items-center w-full">
                            <div className="w-full">
                                <Breadcrumb
                                    className='text-white'
                                    separator=">"
                                    items={[
                                        {
                                            title: 'Category',
                                        },
                                        {
                                            title: 'Robot kits',
                                        },
                                    ]}
                                />

                                <p className='text-[52px] font-[600] mt-5'>Robot kits</p>
                                <p className='text-[21px] font-[400]'>
                                    RoboCentre is the premiere destination for
                                    building Science, Technology, Engineering, Arts and
                                </p>
                            </div>

                            <div className="w-full">
                                <img className='flex mr-auto ml-auto' src={mingoRobot} alt="" />
                            </div>
                        </div>
                    </div>

                    {/* prodcts and filters */}
                    <div className="w-full lg:flex gap-[30px] mb-[100px]">
                        {/* filters */}
                        <div className='w-1/4 bg-white px-[40px] h-fit pt-5 pb-[100px]'>
                            ...
                        </div>

                        {/* products */}
                        <div className='w-full h-full col-span-2 mt-[54px] lg:px-[30px] px-3'>
                            <div className='lg:grid lg:grid-cols-3 w-full gap-5'>
                                {
                                    isBusy ?
                                        tempProducts.map((item, i) => (
                                            <SingleProductShimmer key={i} />
                                        ))
                                        :
                                        allNewProducts.map((item: any, i) => (
                                            <SingleProduct key={i} theProduct={item} />
                                        ))
                                }
                            </div>

                            {/* pagination */}
                            <div className="mt-10 flex justify-end mx-10">
                                <Pagination defaultCurrent={1} total={total} />
                            </div>
                        </div>
                    </div>

                    {/* footer */}
                    <div>
                        <CustomFooter />
                    </div>

                </div>
            </div>
        </>
    )
}

export default ProductListings
