import React, { useEffect, useState } from 'react'
import PinInput from 'react-pin-input';
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import ShowAlert from '../../components/alerts/all_alerts';
import { message, Spin } from 'antd';
import { StorageBox } from '../../helpers/stroage';



const AuthOtpVerify = () => {

    const [isBusy, setIsBusy] = useState(false)
    const [theEmail, setTheEmail] = useState("")
    const [theCode, setTheCode] = useState("")

    //onload
    useEffect(() => {
        const d_email = localStorage.getItem("signUpEmail") ?? ""
        setTheEmail(d_email)
    }, [])

    //handleVerification
    const handleVerification = async () => {
        if (theCode.length < 5) {
            message.warning("Please enter the correct code provided")
        } else {

            const verify_info = {
                "token": theCode
            }

            setIsBusy(true)

            try {
                const response = await BaseService.put_api(global_variables.verify_account, verify_info)
                console.log("authVerifyRes:", response?.data)
                setIsBusy(false)
                StorageBox.saveUserData(response?.data?.payload)
                StorageBox.saveAccessToken(response?.data?.token)
                ShowAlert.success_alert("Verification Successful", "Congratulations, Your account has been verified.", "Continue")
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/cart"
                        }
                    })
            } catch (error: any) {
                console.log("authVerifyErr:", error)
                setIsBusy(false)
                StorageBox.clearStorage()
                ShowAlert.error_alert("Verification Error", error?.response?.data?.error || "An unexpected error occured. Please try again", "Retry")
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/"
                        }
                    })
            }
        }
    }

    return (
        <>
            <div className="flex flex-col justify-center items-center h-screen overflow-hidden p-5">
                <p className='text-[30px] font-[600] text-defaultBlue'>
                    Verify Your Account
                </p>

                <p className='text-[21px] font-[400] text-deepBlue mt-[24px] text-center 2xl:w-[350px] xl:w-[350px] lg:w-[350px] md:w-[350px] w-full'>
                    We have sent a verification code to <br />
                    <span className='font-[600]'>{theEmail || "the email you provided"}</span>
                </p>

                <div className='flex justify-center mt-[32px]'>
                    <PinInput
                        length={5}
                        secret
                        secretDelay={100}
                        onChange={(value, index) => setTheCode(value)}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: '5px' }}
                        inputStyle={{ borderColor: '#6666EA', borderRadius: '10px', height: '60px', width: '60px' }}
                        inputFocusStyle={{ borderColor: '#15C2A5' }}
                        // onComplete={(value, index) => handleVerification(value)}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        disabled={isBusy}
                    />
                </div>

                <div className="mt-10 w-full flex justify-center">
                    <button className="h-[52px] w-[300px] rounded-[45px] bg-defaultBlue text-white disabled:cursor-not-allowed" onClick={handleVerification} disabled={isBusy}>
                        {
                            isBusy ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#fff' }} spin />} />
                                :
                                <p className='text-[21px] font-[400]'>Continue</p>
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default AuthOtpVerify
