import React from 'react'
import SingleProduct from '../../components/shared/singleProduct'
import SingleProductShimmer from '../../components/shared/singleProductShimmer'
import { useCart } from '../../context/generalContext'


const BestSellerProducts = () => {

    const tempList = Array.from({ length: 6 })
    const { isFetching, allNewProducts } = useCart()


    return (
        <>
            <div className='text-deepBlue'>
                <p className='text-[30px] font-[600] text-center'>Best Seller Products</p>

                <p className='text-[16px] font-[400] text-center mt-[16px]'>Top view in this week</p>

                <div className='mt-[32px] flex gap-[5px] items-center justify-center'>
                    <div className='2xl:grid xl:grid lg:grid md:grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-[25px] w-full'>
                        {
                            isFetching ?
                                tempList.map((item, i) => (
                                    <SingleProductShimmer key={i} />
                                ))
                                :
                                allNewProducts.map((item: any, i: number) => (
                                    <SingleProduct key={i} theProduct={item} />
                                ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BestSellerProducts
