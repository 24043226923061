import React, { useState } from 'react'
import { Input, message, Spin } from 'antd'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import PhoneInput, { Value } from "react-phone-number-input";
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { StorageBox } from '../../helpers/stroage';

interface theProps {
    handleClose: () => void
}


const SignUp = ({ handleClose }: theProps) => {

    const [fName, setFName] = useState("")
    const [phoneNum, setPhoneNum] = useState<Value | undefined>("")
    const [lName, setLName] = useState("")
    const [email, setEmail] = useState("")
    const [thePwd, setThePwd] = useState("")
    const [pwdVisible, setPwdVisible] = useState(false)
    const [isBusy, setIsBusy] = useState(false)


    //handleContinue
    const handleContinue = async () => {
        if (!fName || !lName || !phoneNum || !email || !thePwd) {
            message.warning("Please fill out all fields")
        } else {

            let signup_data = {
                "email": email,
                "password": thePwd,
                "firstName": fName,
                "lastName": lName,
                "phone": phoneNum
            }

            setIsBusy(true)
            localStorage.setItem("signUpEmail", email)

            try {
                const response = await BaseService.post_api(global_variables.signup_route, signup_data)
                console.log("signupRes:", response?.data)
                StorageBox.saveAccessToken(response?.data?.token)
                message.success("Account was created successfully")
                setTimeout(() => {
                    window.location.href = "/verify-account"
                }, 1000)
            } catch (error: any) {
                console.log("signupError:", error)
                message.error(error?.response?.data?.error || "An unexpected error occured. Please try again")
                setIsBusy(false)
            }
        }
    }


    return (
        <>
            <div className='w-full text-deepBlue'>
                <p className='text-[21px] font-[400] text-center'>Let's get started by creating your account.</p>

                <div className="mt-[20px] w-full">
                    <label htmlFor='fName' className='text-[16px] font-[400]'>First Name</label>
                    <Input id='fName' className='rounded-[8px] h-[45px]' value={fName} onChange={(e) => setFName(e.target.value)} placeholder='Eg: John' disabled={isBusy} />
                </div>

                <div className="mt-[16px] w-full">
                    <label htmlFor='lName' className='text-[16px] font-[400]'>Last Name</label>
                    <Input id='lName' className='rounded-[8px] h-[45px]' value={lName} onChange={(e) => setLName(e.target.value)} placeholder='Eg: Doe' disabled={isBusy} />
                </div>

                <div className='mt-[16px]'>
                    <label htmlFor="phone" className='text-[12px] font-[500] text-[#9D9CC2]'>Phone</label>
                    <PhoneInput
                        className="rounded-[5px] w-full h-[45px] border border-gray-300 px-2"
                        defaultCountry="GH"
                        value={phoneNum}
                        onChange={(phone) => setPhoneNum(phone)}
                        placeholder="Eg: +233247360007"
                        numberInputProps={{
                            className: "focus:outline-none disabled:cursor-not-allowed rounded-[50px] px-3"
                        }}
                        disabled={isBusy}
                    />
                </div>

                <div className="mt-[16px] w-full">
                    <label htmlFor='email' className='text-[16px] font-[400]'>Email</label>
                    <Input id='email' className='rounded-[8px] h-[45px]' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Eg: johndoe@gmail.com' disabled={isBusy} />
                </div>

                <div className="mt-[16px] w-full relative">
                    <label htmlFor='pwd' className='text-[16px] font-[400]'>Password</label>
                    <Input id='pwd' className='rounded-[8px] h-[45px]' type={pwdVisible ? 'text' : 'password'} value={thePwd} onChange={(e) => setThePwd(e.target.value)} placeholder='Eg: johndoe@gmail.com' disabled={isBusy} />

                    {
                        pwdVisible ?
                            <FaEyeSlash className='h-5 w-5 cursor-pointer text-gray-500 absolute right-3 bottom-4' onClick={() => setPwdVisible(false)} />
                            :
                            <FaEye className='h-5 w-5 cursor-pointer text-gray-500 absolute right-3 bottom-4' onClick={() => setPwdVisible(true)} />
                    }
                </div>

                <div className="mt-[24px] w-full">
                    <button className='w-full h-[63px] rounded-[50px] bg-defaultBlue text-white disabled:cursor-pointer' disabled={isBusy} onClick={handleContinue}>
                        {
                            isBusy ? <Spin indicator={<LoadingOutlined style={{ fontSize: 26, color: '#fff' }} spin />} />
                                :
                                "Continue"
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default SignUp
