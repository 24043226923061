
const SingleProductShimmer = () => {

    return (
        <>
            <div className="p-[10px] bg-white shadow-sm cursor-pointer border-[1px] border-white rounded-[10px] mb-5 lg:mb-0 animate-pulse">
                <div className='w-full relative'>
                    <div className="w-full h-[184px] rounded-[10px] bg-gray-300"></div>
                    {/* <div className='px-[8px] rounded-[12px] bg-gray-400 w-[100px] h-6 absolute bottom-[20px] left-[20px]'></div> */}
                </div>

                <div className="w-full p-[10px] mt-[16px]">
                    <div className='flex gap-[10px] items-center'>
                        <div className='rounded-[12px] bg-gray-300 w-[100px] h-6'></div>
                    </div>

                    <div className='rounded-[12px] bg-gray-300 lg:w-[250px] w-full h-4 mt-[16px]'></div>

                    <div className='rounded-[12px] bg-gray-300 lg:w-[250px] w-full h-2 mt-[16px]'></div>
                    <div className='rounded-[12px] bg-gray-300 lg:w-[200px] w-full h-2 mt-[3px]'></div>


                    <div className="mt-[16px] mb-5 flex gap-[18px] items-center">
                        <div className='rounded-[12px] bg-gray-300 w-[80px] h-5'></div>
                        <div className='rounded-[12px] bg-gray-300 w-[80px] h-5'></div>
                    </div>

                    <div className="mt-[4px]">
                        <div className='rounded-[12px] bg-gray-300 w-[200px] h-5'></div>
                    </div>

                    <div className="mt-[16px] flex justify-end gap-[10px] items-center">
                        <div className='rounded-full bg-gray-300 w-6 h-6'></div>
                        <div className='rounded-full bg-gray-300 w-6 h-6'></div>

                        <div className='px-[30px] py-[7px] h-[40px] w-[100px] rounded-[30px] bg-gray-300'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleProductShimmer
