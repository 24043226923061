import React, { useState } from 'react'
import OngoingOrders from './ongoingOrders'
import CompletedOrders from './completedOrders'


const OrdersTab = () => {

    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = ["Ongoing", "Completed"]

    //tabComponents
    const tabComponents = [
        <OngoingOrders />,
        <CompletedOrders />
    ]

    return (
        <>
            <div className='px-[24px]'>
                <p className='text-[30px] font-[600] text-deepBlue'>Orders</p>

                <div className="mt-5 flex items-center gap-5">
                    {
                        tabNames.map((tab, i) => (
                            <div key={i} className={`${activeTab === i ? 'border-b-[2px] border-b-defaultBlue font-[500]' : 'border-b-[2px] border-b-transparent font-[400]'} px-2 cursor-pointer`} onClick={() => setActiveTab(i)}>
                                <p className='text-[16px]'>{tab}</p>
                            </div>
                        ))
                    }
                </div>

                <div className="mt-5">
                    {
                        tabComponents[activeTab]
                    }
                </div>
            </div>
        </>
    )
}

export default OrdersTab
