import BaseService from "../helpers/baseServices";
import { global_variables } from "../helpers/globalVariables";

export const GetProductCategories = async () => {
  try {
    const response = await BaseService.get_api(
      `${global_variables.product_categories}?limit=8`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetAllProduct = async () => {
  try {
    const response = await BaseService.get_api(
      `${global_variables.all_products}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
