import React, { useState } from 'react'
import { Input, message, Modal, Select, Spin } from 'antd'
import PhoneInput, { Value } from "react-phone-number-input";
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import OtpVerification from './otpVerification';
import VerifyTransaction from './verifyTransaction';

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const DepositModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [phoneNum, setPhoneNum] = useState<Value | undefined>("")
    const [network, setNetwork] = useState<any>("")
    const [theAmount, setTheAmount] = useState<any>("")
    const [theEmail, setTheEmail] = useState<any>("")
    const [isBusy, setIsBusy] = useState(false)
    const [initialResponse, setInitialResponse] = useState<any>(null)


    //initiatePayment
    const initiatePayment = async () => {
        if (!network || !phoneNum || !theAmount || !theEmail) {
            message.warning("Please fill out all fields")
        } else {
            const payment_data = {
                "amount": parseFloat(theAmount),
                "email": theEmail,
                "country": "65d4b85e9ae65ff72c90900e",
                "mobile_money": {
                    "phone": phoneNum,
                    "provider": network
                }
            }

            console.log("dataToPass:", payment_data)
            setIsBusy(true)

            try {
                const response = await BaseService.post_api(global_variables.initiate_deposit, payment_data)
                console.log("depositRes:", response?.data)
                setInitialResponse(response?.data?.payload?.payload)
                setIsBusy(false)
            } catch (error: any) {
                console.log("depositErr:", error)
                message.error(error?.response?.data?.error || "An error occured. Please try again")
                setIsBusy(false)
            }
        }
    }


    return (
        <>
            <Modal open={isOpened} onCancel={handleClose} centered footer={null} maskClosable={false} keyboard={false} closeIcon={!isBusy}>
                {
                    initialResponse?.transaction?.response === "send_otp" ?
                        <>
                            <OtpVerification response={initialResponse} handleClose={handleClose} />
                        </>
                        :
                        initialResponse?.transaction?.response === "pay_offline" ?
                            <>
                                <VerifyTransaction response={initialResponse} handleClose={handleClose} />
                            </>
                            :
                            <>
                                <Spin spinning={isBusy} indicator={<LoadingOutlined style={{ fontSize: 52, color: 'black' }} spin />}>
                                    <p className='text-lg font-semibold'>Deposit Funds</p>
                                    <hr />

                                    {/* main */}
                                    <div className='full mt-5'>
                                        <div>
                                            <small>Choose Network</small>
                                            <Select
                                                className='w-full h-[40px]'
                                                options={[
                                                    { label: "MTN GH", value: "mtn" },
                                                    { label: "Telecel", value: "vod" },
                                                    { label: "AT", value: "atl" }
                                                ]}
                                                onChange={(selected) => setNetwork(selected)}
                                                placeholder="Click to choose"
                                                allowClear
                                            />
                                        </div>

                                        <div className='mt-3'>
                                            <small>Enter Phone Number</small>
                                            <PhoneInput
                                                className="rounded-[5px] w-full h-[40px] border border-gray-300 px-2"
                                                defaultCountry="GH"
                                                value={phoneNum}
                                                onChange={(phone) => setPhoneNum(phone)}
                                                placeholder="E.g: 0241234567"
                                                numberInputProps={{
                                                    className: "focus:outline-none disabled:cursor-not-allowed rounded-[50px] px-3"
                                                }}
                                            />
                                        </div>

                                        <div className='mt-3'>
                                            <small>Enter Amount</small>
                                            <Input className='w-full h-[40px]' type='number' value={theAmount} onChange={(e) => setTheAmount(e.target.value)} min={0} placeholder='E.g: 150' allowClear />
                                        </div>

                                        <div className='mt-3'>
                                            <small>Enter Email</small>
                                            <Input className='w-full h-[40px]' type='email' value={theEmail} onChange={(e) => setTheEmail(e.target.value)} placeholder='E.g: example@gmail.com' allowClear />
                                        </div>
                                    </div>

                                    <hr className='my-5' />
                                    {/* footer */}
                                    <div className="flex w-full justify-end gap-5 items-center mt-5">
                                        <button onClick={handleClose}>
                                            Cancel
                                        </button>

                                        <button className='px-4 py-2 bg-defaultBlue text-white rounded-[5px]' onClick={initiatePayment}>
                                            Proceed
                                        </button>
                                    </div>
                                </Spin>
                            </>
                }
            </Modal>
        </>
    )
}

export default DepositModal
