import React, { useState } from 'react'
import PinInput from 'react-pin-input';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Modal, Spin } from 'antd';
import Lottie from "lottie-react";
import otpAnimation from "../../../animations/otp-animation.json"
import { VerifyPaymentOTP } from '../../../functions/directPayment/directPayments';
import { useParams } from 'react-router-dom';
import ShowAlert from '../../../components/alerts/all_alerts';


interface theProps {
    isOpened: boolean
    response: any
    handleClose: () => void
}


const MomoPaymentVerification = ({ isOpened, response, handleClose }: theProps) => {

    const { id } = useParams()
    const [isBusy, setIsBusy] = useState(false)

    console.log(response)

    //handleVerification
    const handleVerification = (theCode: any) => {
        const otp_info = {
            "otp": theCode,
            "reference": response?.payload?.transaction?.reference
        }

        setIsBusy(true)

        VerifyPaymentOTP(otp_info).then((verifyRes: any) => {
            console.log("otpVerifyRes:", verifyRes)
            message.success("Phone number has been verified")
            setTimeout(() => {
                window.location.href = `/verify-payment/${verifyRes?.reference}`
            }, 1000)
        }).catch((error: any) => {
            console.log("otpVerifyErr:", error)
            setIsBusy(false)
            ShowAlert.error_alert("Verification Failed!", error?.response?.data?.error, "Retry").then((result) => {
                if (result.isConfirmed) {
                    window.location.href = `/payment/${id}`
                }
            })
        })
    }


    return (
        <>
            <Modal open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={false} centered>
                <Spin spinning={isBusy} indicator={<LoadingOutlined style={{ fontSize: 52, color: 'black' }} spin />}>
                    <p className='text-lg font-semibold'>OTP Verification</p>
                    <hr />

                    <p className='mt-3 text-center text-lg'>{response?.transaction?.display_text || "Please enter the one-time password sent to your phone"}</p>

                    <div className="mt-3 w-[200px] flex ml-auto mr-auto">
                        <Lottie animationData={otpAnimation} loop={true} />
                    </div>

                    <div className='flex justify-center mt-5'>
                        <PinInput
                            length={6}
                            secret
                            secretDelay={100}
                            // onChange={(value, index) => { }}
                            type="numeric"
                            inputMode="number"
                            style={{ padding: '5px' }}
                            inputStyle={{ borderColor: '#6666EA', borderRadius: '10px', height: '60px', width: '60px' }}
                            inputFocusStyle={{ borderColor: '#15C2A5' }}
                            onComplete={(value, index) => handleVerification(value)}
                            autoSelect={true}
                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            disabled={isBusy}
                        />
                    </div>

                    <div className='flex justify-center mt-5'>
                        <button className='text-red-500' onClick={handleClose}>
                            Cancel Transaction
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default MomoPaymentVerification
