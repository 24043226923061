import React, { useEffect, useState } from 'react'
import { Input, message, Select, Spin, Steps } from 'antd'
import { FaArrowLeft, FaMobileAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import PhoneInput, { Value } from "react-phone-number-input";
import { useCart } from '../../../context/generalContext';
import { IoWalletOutline } from 'react-icons/io5';
import BalanceCard from '../../wallet/balanceCard';
import { getWalletData } from '../../../functions/getWalletBalance';
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../../helpers/baseServices';
import { InitiatePayment } from '../../../functions/directPayment/directPayments';
import ShowAlert from '../../../components/alerts/all_alerts';
import MomoPaymentVerification from './momoPaymentVerification';


const PaymentPage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { cart, setCart } = useCart();
    const [cartTotals, setCartTotals] = useState<any>(null)
    const [currStep, setCurrStep] = useState(0)
    const [theCountry, setTheCountry] = useState("")
    const [walletBalance, setWalletBalance] = useState<any>(null)
    const [walletId, setWalletId] = useState("")
    const [isPaying, setIsPaying] = useState(false)

    //momo
    const [openMomoOtp, setOpenMomoOtp] = useState(false)
    const [momoNum, setMomoNum] = useState<Value | undefined>("")
    const [momoEmail, setMomoEmail] = useState("")
    const [momoProvider, setMomoProvider] = useState<any>(null)
    const [currMethod, setCurrMethod] = useState(0)
    const [momoResponse, setMomoResponse] = useState<any>(null)

    //payment method options
    const payMethods = [
        // { name: "Credit Card", icon: <FaCreditCard /> },
        { name: "Mobile Money", icon: <FaMobileAlt /> },
        { name: "Robocentre Wallet", icon: <IoWalletOutline /> },
    ]

    //checkWalletBalance
    const checkWalletBalance = () => {
        getWalletData().then((response) => {
            setWalletBalance(response?.amount)
            setWalletId(response?._id)
            setTheCountry(response?.country)
        }).catch((error) => {
            console.log("myBalanceErr:", error)
        })
    }

    //onload
    useEffect(() => {
        const savedCart = localStorage.getItem('cartItems');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }

        const fromStorage = localStorage.getItem("cartTotal" ?? null)
        if (fromStorage) {
            setCartTotals(JSON.parse(fromStorage))
        }

        checkWalletBalance()
        setCurrStep(1)

        // eslint-disable-next-line
    }, [])


    //handlePayNow
    const handlePayNow = () => {
        //pay via wallet
        if (currMethod === 1) {
            console.log("---pay with wallet")
            if (walletBalance < cartTotals?.grandTotal) {
                message.warning("Please top up wallet balance to proceed.")
            } else {
                let pay_wallet_data = {
                    "walletId": walletId,
                    "salesId": id,
                    "salesCountryId": theCountry
                }

                setIsPaying(true)
                BaseService.post_api(`/robocentre/purchase`, pay_wallet_data)
                    .then((response) => {
                        console.log("walletPaidRes", response?.data)
                        setIsPaying(false)
                        ShowAlert.success_alert("Payment Successful!", "Congratulations on completing your purchase.", "View Invoice").then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/invoice/${response?.data?.payload?.salesId}`)
                            }
                        })
                    }).catch((error: any) => {
                        console.log("walletPaidErr:", error)
                        setIsPaying(false)
                        ShowAlert.error_alert("Payment Failed!", error?.response?.data?.error, "Retry").then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    })
            }
        } else {
            //pay via momo
            console.log("---pay with momo")
            if (!momoProvider) {
                message.warning("Please select a payment provider")
            } else if (!momoNum) {
                message.warning("Please enter your phone number")
            } else if (!momoEmail) {
                message.warning("Please enter your email")
            } else {
                setIsPaying(true)

                let pay_momo_data = {
                    "amount": 0.1,
                    "salesId": id,
                    "email": momoEmail,
                    "country": "65d4b85e9ae65ff72c90900e",
                    "mobile_money": {
                        "phone": momoNum,
                        "provider": momoProvider
                    }
                }

                InitiatePayment(pay_momo_data).then((response: any) => {
                    console.log("initMomoRes:", response)
                    setMomoResponse(response)
                    //if we have to enter otp
                    if (response?.payload?.transaction?.response === "send_otp") {
                        setOpenMomoOtp(true)
                        setIsPaying(false)
                    } else {
                        //if we have to pay direct
                        window.location.href = `/verify-payment/${response?.payload?.transaction?.reference}`
                    }
                }).catch((error: any) => {
                    console.log("initMomoErr:", error)
                    setIsPaying(false)
                    message.error(error?.response?.data?.error || "An error occured please try again")
                })
            }
        }
    }

    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] mt-[25px] text-deepBlue'>
                        <p className='text-[32px] font-[700] text-center'>Payment</p>

                        <div className='mt-5'>
                            <Steps
                                current={1}
                                progressDot
                                items={[
                                    {
                                        title: 'Your Address',
                                        status: `${currStep === 0 ? 'process' : 'finish'}`
                                    },
                                    {
                                        title: 'Payment Method',
                                        status: `${currStep === 1 ? 'process' : 'wait'}`
                                    },
                                ]}
                            />
                        </div>

                        <div className="xl:grid lg:grid md:grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-[25px] w-full mt-[24px] pb-10">
                            {/* left */}
                            <div className='xl:col-span-2 lg:col-span-2 w-full'>
                                <div className='w-full bg-white border border-[#CCCCF8] px-5 pt-5 pb-[50px] rounded-[10px]'>
                                    <div className='mt-5 flex gap-[10px]'>
                                        {
                                            payMethods.map((tab, i) => (
                                                <div key={i} className={`${currMethod === i ? 'bg-defaultBlue bg-opacity-[10%] text-defaultBlue' : ''} flex gap-[10px] items-center border border-[#CCCCF8] px-[34px] py-[9px] rounded-[30px] cursor-pointer text-deepBlue hover:text-defaultBlue hover:bg-defaultBlue hover:bg-opacity-[10%]`} onClick={() => setCurrMethod(i)}>
                                                    {tab.icon}
                                                    <p className='text-[16px] font-[400]'>{tab.name}</p>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    {/* form */}
                                    <div className="mt-[26px]">
                                        {
                                            currMethod === 0 ?
                                                //credit card
                                                // <>
                                                //     <div className="lg:grid lg:grid-cols-2 gap-5 w-full">
                                                //         <div>
                                                //             <label htmlFor="cNum" className='text-[12px] font-[500] text-[#9D9CC2]'>Card Number *</label>
                                                //             <Input className='w-full h-[40px]' id="cNum" placeholder='E.g: 4458-5875-7789-2145' />
                                                //         </div>

                                                //         <div>
                                                //             <label htmlFor="hName" className='text-[12px] font-[500] text-[#9D9CC2]'>Holder Name *</label>
                                                //             <Input className='w-full h-[40px]' id='hName' placeholder='E.g: John Doe' />
                                                //         </div>

                                                //         <div>
                                                //             <label htmlFor="date" className='text-[12px] font-[500] text-[#9D9CC2]'>Email</label>
                                                //             <DatePicker className='w-full h-[40px]' id='date' />
                                                //         </div>

                                                //         <div>
                                                //             <label htmlFor="cvv" className='text-[12px] font-[500] text-[#9D9CC2]'>CVC / CVV *</label>
                                                //             <Input className='w-full h-[40px]' id='cvv' placeholder='E.g: +233247360007' />
                                                //         </div>
                                                //     </div>

                                                //     <div className='flex gap-1 items-center mt-5'>
                                                //         <Checkbox className='h-5 w-5' />
                                                //         <p className='text-[13px] font-[400] mt-[2px]'>Save card for future</p>
                                                //     </div>
                                                // </>
                                                // :
                                                //mobile money
                                                <>
                                                    <div className="lg:grid lg:grid-cols-2 gap-5 w-full">
                                                        <div>
                                                            <label htmlFor="prov" className='text-[12px] font-[500] text-[#9D9CC2]'>Choose Network</label>
                                                            <Select className='w-full h-[40px]' id="prov" placeholder="Click to choose" options={[
                                                                { label: "MTN GH", value: "mtn" },
                                                                { label: "Telecel", value: "vod" },
                                                                { label: "AT", value: "atl" }
                                                            ]}
                                                                onChange={(selected) => setMomoProvider(selected)}
                                                                disabled={isPaying}
                                                            />
                                                        </div>

                                                        <div>
                                                            <label htmlFor="num" className='text-[12px] font-[500] text-[#9D9CC2]'>Phone Number</label>
                                                            <PhoneInput
                                                                className="rounded-[5px] w-full h-[40px] border border-gray-300 px-2"
                                                                defaultCountry="GH"
                                                                value={momoNum}
                                                                onChange={(phone) => setMomoNum(phone)}
                                                                placeholder="E.g: 0240000000"
                                                                numberInputProps={{
                                                                    className: "focus:outline-none disabled:cursor-not-allowed rounded-[50px] px-3"
                                                                }}
                                                                disabled={isPaying}
                                                            />
                                                        </div>

                                                        <div>
                                                            <label htmlFor="momomail" className='text-[12px] font-[500] text-[#9D9CC2]'>Your Email</label>
                                                            <Input className='w-full h-[40px]' value={momoEmail} onChange={(e) => setMomoEmail(e.target.value)} id='momomail' placeholder='E.g: johndoe@gmail.com' disabled={isPaying} />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                //robocentre wallet
                                                <>
                                                    <div className='w-full'>
                                                        <BalanceCard />
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="mt-[30px] flex justify-between items-center">
                                    <button className='flex gap-[10px] items-center text-gray-600 border border-gray-600 rounded-[40px] h-[45px] px-8 disabled:cursor-not-allowed' onClick={() => navigate(`/checkout/${id}`)} disabled={isPaying}>
                                        <FaArrowLeft className='h-4 w-4' />
                                        <p>Back</p>
                                    </button>

                                    <button className='h-[45px] text-white border bg-defaultBlue rounded-[40px] px-8 disabled:cursor-not-allowed' onClick={handlePayNow} disabled={isPaying}>
                                        {
                                            isPaying ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                :
                                                <p>Pay Now</p>
                                        }
                                    </button>
                                </div>
                            </div>

                            {/* right */}
                            <div className='w-full bg-[#F2F2FE] p-5 rounded-[10px] mt-5 lg:mt-0 h-fit'>
                                <p className='text-[16px] font-[700]'>Order Summary</p>

                                <div className="mt-[17px] border-y border-y-gray-300 py-5">
                                    {
                                        cart.map((item: any, i: number) => (
                                            <div className='flex justify-between'>
                                                <div key={i} className='flex gap-[12px] mb-5'>
                                                    <div>
                                                        <img className='h-[50px] w-[75px] object-cover rounded-[10px]' src={item?.images?.[0]} alt="" />
                                                    </div>
                                                    <div>
                                                        <p className='text-[16px] font-[700] text-deepBlue'>{item?.name || ""}</p>
                                                        <p className='text-[16px] font-[400] text-deepBlue'>
                                                            Amount: <span className='text-defaultBlue'>{item?.countryData?.currencyCode + " " + item?.salePrice}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='text-[16px] font-[700] text-deepBlue'>x{item?.itemQty}</p>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="mt-5">
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[400] text-deepBlue'>Sub Total</p>
                                        <p className='text-[16px] font-[400] text-deepBlue'>GHS {cartTotals?.subTotal.toFixed(2) || 0.00}</p>
                                    </div>

                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[400] text-deepBlue'>Tax</p>
                                        <p className='text-[16px] font-[400] text-deepBlue'>GHS {cartTotals?.tax.toFixed(2) || 0.00}</p>
                                    </div>

                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[400] text-deepBlue'>Shipping</p>
                                        <p className='text-[16px] font-[400] text-deepBlue'>GHS {cartTotals?.shipping.toFixed(2) || 0.00}</p>
                                    </div>

                                    <div className='mt-5 flex justify-center py-[15px] px-[40px] rounded-[40px] h-[60px] w-full bg-[#9999F1] bg-opacity-[20%]'>
                                        <p className='text-[21px] font-[700] text-deepBlue'>Total Order: GHS {cartTotals?.grandTotal.toFixed(2) || 0.00}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* trigger momo otp */}
            {
                openMomoOtp && <MomoPaymentVerification isOpened={openMomoOtp} handleClose={() => setOpenMomoOtp(false)} response={momoResponse} />
            }
        </>
    )
}

export default PaymentPage
