import React from 'react'
import { HiMagnifyingGlass } from 'react-icons/hi2'

const SearchBar = () => {
    return (
        <>
            <div className='w-full lg:w-[577px] py-[10px] flex justify-center relative'>
                <input className='w-full md:w-[577px] lg:-[577px] xl:-[577px] h-[40px] rounded-[30px] shadow-sm bg-white focus:outline-none pl-10 pr-[14px]' placeholder='Search Product' />
                <HiMagnifyingGlass className='h-4 w-4 text-gray-300 absolute left-4 md:left-[190px] lg:left-4 xl:left-4 bottom-[22px] z-50' />
            </div>
        </>
    )
}

export default SearchBar
