import BaseService from "../helpers/baseServices";
import { global_variables } from "../helpers/globalVariables";

//check transaction status
export const getTransactionStatus = async (transactionRef: any) => {
    if (transactionRef) {
        const maxAttempts = 20;

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            try {

                console.log(`Verification Attempt ${attempt}`);

                const verify_body = {
                    "reference": transactionRef
                }

                const response = await BaseService.post_api(`${global_variables.manual_transaction_verify}`, verify_body);

                const statusCode = response?.data?.payload?.transaction?.status;

                if (statusCode === "Successful" || statusCode === "Failed") {
                    return response?.data;
                }

                // Wait for a while before the next attempt
                await new Promise((resolve) => setTimeout(resolve, 15000)); // 15 seconds
            } catch (error) {
                // Handle error if needed
                return error;
            }
        }

        // If not successful after 10 attempts, return an error or handle it accordingly
        return { error: "Max attempts reached, transaction status not updated." };
    }
};