import React from 'react'
import { Tag } from 'antd'
import { BiTrash } from 'react-icons/bi'

const CompletedOrders = () => {

    const tempData = Array.from({ length: 3 })

    return (
        <>
            <div className="h-full">
                {/* heading */}
                <div className='w-full grid grid-cols-5 text-deepBlue'>
                    <div className="w-full col-span-2">
                        <p className='text-[16px] font-[600]'>Product</p>
                    </div>

                    <div className="w-full">
                        <p className='text-[16px] font-[600]'>Price</p>
                    </div>

                    <div className="w-full col-span-2">
                        <p className='text-[16px] font-[600]'>Status</p>
                    </div>
                </div>

                <hr className='border-[#E0E0FB]' />

                {/* list */}
                <div className="mt-2">
                    {
                        tempData.map((item, i) => (
                            <div key={i} className='w-full grid grid-cols-5 text-deepBlue items-center border-b border-b-[#E0E0FB] pb-2 mb-5'>
                                <div className="w-full col-span-2 flex gap-2">
                                    <div>
                                        <img className='h-[70px] w-[114px] rounded-[5px] object-cover' src="https://t3.ftcdn.net/jpg/05/90/82/32/360_F_590823233_97YNah2bYsEW9llwf7UNK5L3r1cM0Ei3.jpg" alt="" />
                                    </div>

                                    <div>
                                        <p className='text-[21px] font-[600]'>Hollow Port</p>
                                        <p className='text-[16px] font-[400]'>Awesome yellow t-shirt</p>
                                    </div>
                                </div>

                                <div className="w-full">
                                    <p className='text-[21px] font-[600]'>GHS 78.45</p>
                                </div>

                                <div className="w-full col-span-2 flex gap-5 items-center">
                                    <Tag color='success'>Completed</Tag>

                                    <button className='px-3 py-1 rounded-[30px] bg-defaultBlue text-white'>
                                        <p className='text-[14px]'>Invoice</p>
                                    </button>

                                    <BiTrash className='h-5 w-5 cursor-pointer text-[#6E6C8E]' />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default CompletedOrders
