import React from 'react'
import { useRef } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import roboImg from "../../images/mingoRobot1.png"

const BannerSlider = () => {

    const sliderRef: any = useRef();

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const next = () => {
        sliderRef.current.slickNext();
    };
    const previous = () => {
        sliderRef.current.slickPrev();
    };


    //testimonyList
    const testimonyList = [
        {
            name: <p className="font-[600] text-[14px] leading-[21px]">William S.</p>,
            title: (
                <p className="font-[400] text-[12px] leading-[16px] text-yarpGray mt-[5px]">
                    Logistics Driver
                </p>
            ),
            image: <img src="" alt="william" />,
            testimony: (
                <p className="mt-[22px] text-[14px] leading-[21px] font-[400] text-yarpGray lg:line-clamp-2">
                    “Reliable GPS tracking and great customer support make this app a
                    winner."
                </p>
            ),
        },
        {
            name: <p className="font-[600] text-[14px] leading-[21px]">Mensah F. </p>,
            title: (
                <p className="font-[400] text-[12px] leading-[16px] text-yarpGray mt-[5px]">
                    Logistics Manager
                </p>
            ),
            image: <img src="" alt="mensah" />,
            testimony: (
                <p className="mt-[22px] text-[14px] leading-[21px] font-[400] text-yarpGray lg:line-clamp-2">
                    “This app streamlines our supply chain with user-friendly features
                    like real-time tracking and instant communication
                </p>
            ),
        }
    ];


    return (
        <>
            <div className='relative h-full'>
                <Slider ref={sliderRef} {...settings}>
                    {testimonyList.map((item, i) => (
                        <div
                            key={i}
                            className="xl:h-[413px] lg:h-[413px] md:h-[413px] h-[413px] bg-defaultGreen shadow-sm rounded-[16px] pb-[100px] lg:pb-0 pt-10 lg:pt-0"
                        >
                            <div className='flex gap-[80px] items-center h-full'>
                                <div className='pl-[40px] pr-[40px] lg:pr-0'>
                                    <p className='lg:text-[60px] text-[40px] font-[700] text-white lg:leading-[70px] leading-[40px]'>Trendy Products</p>

                                    <p className='text-[21px] font-[400] mt-[25px] text-white'>Mingo robot: robotics and stem kit</p>

                                    <button className='lg:px-[103px] px-[80px] lg:py-[15px] py-[12px] rounded-[60px] bg-defaultYellow text-white mt-[24px]'>
                                        <p className='lg:text-[21px] text-[16px] font-[400]'>SHOP NOW</p>
                                    </button>
                                </div>

                                <div className='xl:pr-[100px] lg:pr-[100px] md:pr-[30px] xl:block lg:block md:block hidden'>
                                    <img className='xl:w-full lg:w-full md:w-[200px]' src={roboImg} alt="robo" />
                                </div>
                            </div>

                        </div>
                    ))}
                </Slider>

                {/* arrow buttons */}
                <div className="absolute lg:bottom-[40px] bottom-[30px] right-[34px]">
                    <div className="flex gap-[13px]">
                        <button
                            className="h-[48px] w-[48px] rounded-[5px] bg-white bg-opacity-[50%] hover:bg-yarpOrange flex justify-center items-center"
                            onClick={previous}
                        >
                            <FaArrowLeft className="h-5 w-5 text-white" />
                        </button>
                        <button
                            className="h-[48px] w-[48px] rounded-[5px] bg-white bg-opacity-[50%] hover:bg-yarpOrange flex justify-center items-center"
                            onClick={next}
                        >
                            <FaArrowRight className="h-5 w-5 text-white" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerSlider
