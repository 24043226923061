import React, { useState } from 'react'
import { MdOutlineHeadphones } from "react-icons/md";
import { PerformLogout } from '../../helpers/utils';
import CustomeNavbar from '../../components/layout/customeNavbar';
import { BiBasket } from "react-icons/bi";
import { TbHearts, TbGiftCard, TbMapPin, TbBell, TbCreditCard, TbLogout } from "react-icons/tb";
import OrdersTab from './tabs/orders/ordersTab';
import { MdOutlineAccountBox } from "react-icons/md";
import Account from './tabs/account/account';
import WishlistItems from './tabs/wishlist/wishlistItems';


const MyAccount = () => {

    const [activeTab, setActiveTab] = useState(0)

    // tabNames
    const tabNames = [
        {
            name: "Account",
            icon: <MdOutlineAccountBox className='h-5 w-5' />
        },
        {
            name: "Orders",
            icon: <BiBasket className='h-5 w-5' />
        },
        {
            name: "Wishlist",
            icon: <TbHearts className='h-5 w-5' />
        },
        {
            name: "Gift cards",
            icon: <TbGiftCard className='h-5 w-5' />
        },
        {
            name: "Address book",
            icon: <TbMapPin className='h-5 w-5' />
        },
        {
            name: "Notification",
            icon: <TbBell className='h-5 w-5' />
        },
        {
            name: "Payment methods",
            icon: <TbCreditCard className='h-5 w-5' />
        },
        {
            name: "Help centre",
            icon: <MdOutlineHeadphones className='h-5 w-5' />
        },
    ]

    //tabComponents
    const tabComponents = [
        <Account />,
        <OrdersTab />,
        <WishlistItems />
    ]


    return (
        <>
            <div className="h-screen overflow-hidden bg-[#F9F9FF]">
                <CustomeNavbar />

                <div className='w-full h-full 2xl:px-[120px] xl:px-[80px] lg:px-[80px] md:px-[50px] px-[16px] overflow-y-auto pb-20'>
                    <div className="py-5 2xl:grid xl:grid lg:grid md:grid grid-cols-4 gap-5">
                        {/* left */}
                        <div className="w-full col-span-1 bg-white rounded-[5px] shadow-md py-5 h-fit">
                            {
                                tabNames.map((item, i) => (
                                    <div key={i} className={`${activeTab === i ? 'border-l-[2px] border-l-defaultBlue bg-defaultBlue_5 font-[400]' : 'font-[400] border-l-[2px] border-l-transparent'} flex gap-3 items-center text-black cursor-pointer 2xl:px-8 xl:px-8 lg:px-4 md:px-4 px-4 py-2 mb-3`} onClick={() => setActiveTab(i)}>
                                        <div>{item?.icon}</div>
                                        <p className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] text-[12px]'>{item?.name}</p>
                                    </div>
                                ))
                            }

                            <hr className='my-5' />

                            <div className="flex gap-3 items-center text-red-500 font-[400] cursor-pointer px-8 py-2" onClick={PerformLogout}>
                                <div><TbLogout className='h-5 w-5' /></div>
                                <p className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] text-[12px]'>Logout</p>
                            </div>
                        </div>

                        {/* right */}
                        <div className="w-full col-span-3 bg-white rounded-[5px] shadow-md p-[24px] h-full mt-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0">
                            {
                                tabComponents[activeTab]
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyAccount
