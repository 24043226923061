import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";

export const handleLocationDecode = async (selected: any) => {
  if (!selected) {
    console.log("No option selected");
    return {
      error: "No location selected",
    };
  }

  try {
    const results: any = await geocodeByPlaceId(selected?.value?.place_id);
    const { lat, lng } = await getLatLng(results[0]);

    const dropCity = results?.[0]?.address_components?.[2]?.long_name;
    const dropRegion = results?.[0]?.address_components?.[3]?.long_name;
    const dropPlaceName = selected?.value?.description;

    return {
      latitude: lat,
      longitude: lng,
      city: dropCity,
      region: dropRegion,
      placeName: dropPlaceName,
    };
  } catch (error) {
    console.error(error);
    return {
      error: "An error occurred while fetching location data",
    };
  }
};
