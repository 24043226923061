import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import otpAnimation from "../../animations/verify-animation.json"
import successAnimation from "../../animations/success2.json"
import errorAnimation from "../../animations/errorAnimation.json"
import moment from 'moment';
import { getTransactionStatus } from '../../utils/functions';
import { message } from 'antd';


interface theProps {
    response: any
    handleClose: () => void
}


const VerifyTransaction = ({ response, handleClose }: theProps) => {

    const [isVerifying, setIsVerifying] = useState(true)
    const [successMessage, setSuccessMessage] = useState<any>(null)
    const [failMessage, setFailMessage] = useState<any>(null)
    const [maxAttempts, setMaxAttempts] = useState(false)

    //runVerification
    useEffect(() => {

        const runVerification = async () => {
            setIsVerifying(true)
            try {
                const verifyRes = await getTransactionStatus(response?.payment?.textRef)
                console.log("verifyingRes:", verifyRes)
                if (verifyRes?.payload?.transaction?.status === 'Successful') {
                    setSuccessMessage(verifyRes)
                    setIsVerifying(false)
                } else if (verifyRes?.payload?.transaction?.status === 'Failed') {
                    setFailMessage(verifyRes)
                    setIsVerifying(false)
                } else {
                    setMaxAttempts(true)
                    setIsVerifying(false)
                }
            } catch (error) {
                console.log("verifyingErr:", error)
                message.error("An error occured. Please retry")
                setIsVerifying(false)
                handleClose()
            }
        }

        runVerification()

        // eslint-disable-next-line
    }, [])

    return (
        <>
            {
                isVerifying ?
                    // verifying transaction
                    <>
                        <div className="mt-3 w-[150px] flex ml-auto mr-auto">
                            <Lottie animationData={otpAnimation} loop={true} />
                        </div>

                        <div className="text-center text-lg">
                            <p>{response?.transaction?.display_text || 'Please complete the authorisation process by inputting your PIN on your mobile device'}</p>
                        </div>

                        <hr className='my-3' />

                        <div>
                            <p className='font-semibold text-lg mb-2'>Transaction Details</p>

                            <p className='text-md capitalize'><span className='font-semibold'>Action:</span> {response?.payment?.action}</p>

                            <p className='text-md capitalize'><span className='font-semibold'>Amount: </span>
                                {
                                    response?.payment?.action === "deposit" ? "GHS " + response?.payment?.deposit : response?.payment?.countryData?.currencyCode + " " + response?.payment?.credit
                                }
                            </p>

                            <p className='text-md capitalize'><span className='font-semibold'>Reference:</span> {response?.payment?.textRef}</p>

                            <p className='text-md capitalize'><span className='font-semibold'>Date/Time:</span> {moment(response?.payment?.timestamp).format("Do MMM YYYY - h:mm A")}</p>
                        </div>

                        <div className='flex justify-center mt-5'>
                            <button className='text-red-500' onClick={handleClose}>
                                Cancel Transaction
                            </button>
                        </div>
                    </>
                    : successMessage ?
                        // successful transactions
                        <>
                            <div className="mt-3 w-[150px] flex ml-auto mr-auto">
                                <Lottie animationData={successAnimation} loop={true} />
                            </div>

                            <div className="text-center text-lg font-[500] capitalize">
                                <p>{successMessage?.payload?.payment?.display_text}</p>
                            </div>

                            <hr className='my-3' />
                            <div>
                                <p className='font-semibold text-lg mb-2'>Transaction Details</p>

                                <p className='text-md capitalize'><span className='font-semibold'>Action:</span> {successMessage?.payload?.transaction?.action}</p>

                                <p className='text-md capitalize'><span className='font-semibold'>Amount: </span>
                                    {
                                        successMessage?.payload?.transaction?.action === "deposit" ? "GHS " + successMessage?.payload?.transaction?.deposit : successMessage?.payload?.transaction?.countryData?.currencyCode + " " + successMessage?.payload?.transaction?.credit
                                    }
                                </p>

                                <p className='text-md capitalize'><span className='font-semibold'>Reference:</span> {successMessage?.payload?.transaction?.textRef}</p>

                                <p className='text-md capitalize'><span className='font-semibold'>Date/Time:</span> {moment(successMessage?.payload?.transaction?.timestamp).format("Do MMM YYYY - h:mm A")}</p>
                            </div>
                        </>
                        : failMessage ?
                            // failed transaction
                            <>
                                <div className="mt-3 w-[150px] flex ml-auto mr-auto">
                                    <Lottie animationData={errorAnimation} loop={true} />
                                </div>

                                <div className="text-center text-lg font-[500] capitalize">
                                    <p>{successMessage?.payload?.payment?.display_text || "Your transaction has failed. Please retry"}</p>
                                </div>

                                <hr className='my-3' />
                                <div>
                                    <p className='font-semibold text-lg mb-2'>Transaction Details</p>

                                    <p className='text-md capitalize'><span className='font-semibold'>Action:</span> {successMessage?.payload?.transaction?.action}</p>

                                    <p className='text-md capitalize'><span className='font-semibold'>Amount: </span>
                                        {
                                            successMessage?.payload?.transaction?.action === "deposit" ? " GHS " + successMessage?.payload?.transaction?.deposit : successMessage?.payload?.transaction?.countryData?.currencyCode + " " + successMessage?.payload?.transaction?.credit
                                        }
                                    </p>

                                    <p className='text-md capitalize'><span className='font-semibold'>Reference:</span> {successMessage?.payload?.transaction?.textRef}</p>

                                    <p className='text-md capitalize'><span className='font-semibold'>Date/Time:</span> {moment(successMessage?.payload?.transaction?.timestamp).format("Do MMM YYYY - h:mm A")}</p>
                                </div>
                            </>
                            : maxAttempts ?
                                //general error
                                <>
                                    <div className="mt-3 w-[150px] flex ml-auto mr-auto">
                                        <Lottie animationData={errorAnimation} loop={true} />
                                    </div>

                                    <div className="text-center text-lg">
                                        <p>Max attempts reached for verification, transaction status unknown.</p>
                                    </div>
                                </>
                                :
                                //default load
                                <>
                                    <div className="mt-3 w-[150px] flex ml-auto mr-auto">
                                        <Lottie animationData={otpAnimation} loop={true} />
                                    </div>

                                    <div className="text-center text-lg">
                                        <p>Verifying transaction. Please wait...</p>
                                    </div>
                                </>
            }
        </>
    )
}

export default VerifyTransaction
