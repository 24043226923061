import React, { useState } from 'react'
import { Rate, Spin, message } from 'antd'
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/generalContext';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { LoadingOutlined } from '@ant-design/icons';

interface theProps {
    theProduct?: any
}

const SingleProduct = ({ theProduct }: theProps) => {

    const navigate = useNavigate()
    const { cart, setCart } = useCart();
    const [isBusy, setIsBusy] = useState(false)
    const { getNewProducts } = useCart()


    //handleAddToCart
    const handleAddToCart = () => {
        const existingItem = cart.find((item: any) => item._id === theProduct?._id);
        if (existingItem) {
            message.info("This item is already in your cart.")
        } else {
            const updatedCart: any = [...cart, { ...theProduct, itemQty: 1 }];
            setCart(updatedCart);
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
            message.success("Item added to cart")
        }
    }

    //handleAddToWishlist
    const handleAddToWishlist = async () => {

        let wish_data = {
            "productId": theProduct?._id
        }

        setIsBusy(true)

        try {
            const response = await BaseService.post_api(global_variables.add_to_wishlist, wish_data)
            console.log("addWishRes:", response?.data)
            message.success(`${theProduct?.name} was added to wishlist`)
            setIsBusy(false)
            getNewProducts()
        } catch (error: any) {
            console.log("addWishErr:", error)
            message.error(error?.response?.data?.error || "Could not add product to wishlist")
            setIsBusy(false)
        }
    }


    //handleRemoveWishlist
    const handleRemoveWishlist = async () => {

        setIsBusy(true)

        try {
            const response = await BaseService.delete_api(`${global_variables.add_to_wishlist}/${theProduct?._id}`)
            console.log("delWishRes:", response?.data)
            message.success(`${theProduct?.name} was removed from wishlist`)
            setIsBusy(false)
            getNewProducts()
        } catch (error: any) {
            console.log("delWishErr:", error)
            message.error(error?.response?.data?.error || "Could not remove product from wishlist")
            setIsBusy(false)
        }
    }




    return (
        <>
            <div className="p-[10px] bg-white shadow-sm border-[1px] border-white hover:border-defaultBlue rounded-[10px] mb-5 lg:mb-0">
                <div className='w-full relative'>
                    <img className="w-full h-[184px] rounded-[10px] object-cover" src={theProduct?.images[0] || ""} alt="drone" />
                </div>

                <div className="w-full p-[10px] mt-[16px]">
                    <div className='flex gap-[10px] items-center'>
                        <div className='px-[8px] rounded-[12px] w-fit' style={{ backgroundColor: theProduct?.categoryData?.color || "#EBF6FF" }}>
                            <p className='text-[16px] font-[400] text-white'>{theProduct?.categoryData?.name}</p>
                        </div>
                    </div>

                    <p className='text-[21px] font-[600] text-deepBlue mt-[16px] line-clamp-1'>
                        {theProduct?.name || "Product name"}
                    </p>

                    <p className='text-[16px] font-[400] text-lightText mt-[16px] line-clamp-2'>
                        {theProduct?.description || "This is a product description for the product you are currently seeing"}
                    </p>

                    <div className="mt-[16px] flex gap-[18px] items-center">
                        <p className='text-[21px] font-[600] text-defaultBlue'>
                            {theProduct?.countryData?.currencyCode + " " + theProduct?.salePrice}
                        </p>
                        <p className='text-[16px] font-[400] text-defaultRed line-through'>
                            {theProduct?.countryData?.currencyCode + " " + theProduct?.originalPrice}
                        </p>
                    </div>

                    <div className="mt-[4px]">
                        <Rate disabled value={4.5} allowHalf />
                    </div>

                    <div className="mt-[16px] flex justify-end gap-[10px] items-center">
                        {
                            theProduct?.wishListData ? <button className='text-defaultBlue h-[35px] w-[35px] rounded-full flex justify-center items-center hover:bg-defaultBlue hover:text-white' title='Remove from wishlist' onClick={handleRemoveWishlist}>
                                {
                                    isBusy ?
                                        <Spin indicator={<LoadingOutlined className='hover:text-white text-defaultBlue' style={{ fontSize: 24 }} spin />} />
                                        :
                                        <IoMdHeart className='w-[24px] h-[24px]' />
                                }
                            </button>
                                :
                                <button className='text-defaultBlue h-[35px] w-[35px] rounded-full flex justify-center items-center hover:bg-defaultBlue hover:text-white' title='Add to wishlist' onClick={handleAddToWishlist}>
                                    {
                                        isBusy ?
                                            <Spin indicator={<LoadingOutlined className='hover:text-white text-defaultBlue' style={{ fontSize: 24 }} spin />} />
                                            :
                                            <IoMdHeartEmpty className='w-[24px] h-[24px]' />
                                    }
                                </button>
                        }

                        <button className='text-defaultBlue h-[35px] w-[35px] rounded-full flex justify-center items-center hover:bg-defaultBlue hover:text-white' title='Add to cart' onClick={handleAddToCart}>
                            <RiShoppingCart2Line className='w-[24px] h-[24px]' />
                        </button>

                        <button className='px-[30px] py-[7px] rounded-[30px] bg-defaultBlue text-white' onClick={() => navigate(`/products/${theProduct?._id}`)}>
                            <p className='text-[16px] font-[400]'>Buy Now</p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleProduct
