import React from 'react'
import SingleProduct from '../../components/shared/singleProduct'
import SingleProductShimmer from '../../components/shared/singleProductShimmer'
import { useCart } from '../../context/generalContext'

const NewProducts = () => {

    const tempList = Array.from({ length: 6 })
    const { isFetching, allNewProducts } = useCart()


    return (
        <>
            <div className='2xl:grid xl:grid lg:grid md:grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-[25px] w-full'>
                {
                    isFetching ?
                        tempList.map((item, i) => (
                            <SingleProductShimmer key={i} />
                        ))
                        :
                        allNewProducts.map((item: any, i: number) => (
                            <SingleProduct key={i} theProduct={item} />
                        ))
                }
            </div>
        </>
    )
}

export default NewProducts
