import React from 'react'
import { Modal } from 'antd'
import { IoCloseOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    product?: any
}

const AddedToCartDialog = ({ isOpened, handleClose, product }: modalProps) => {

    const navigate = useNavigate()

    return (
        <>
            <Modal width={400} open={isOpened} maskClosable={false} closeIcon={false} footer={null}>
                <div>
                    {/* header */}
                    <div className='flex justify-between items-center'>
                        <p className='text-[21px] font-[700] text-deepBlue'>
                            ✅ Item added to cart
                        </p>

                        <button className='h-5 w-5 text-[#989898]' onClick={handleClose}>
                            <IoCloseOutline className='h-5 w-5' />
                        </button>
                    </div>

                    <div className="mt-[30px] flex gap-[20px]">
                        <img className='h-[97px] w-[140px] rounded-[10px] object-cover' src={product?.images[0] || ""} alt="item" />

                        <div className='flex flex-col justify-center'>
                            <p className='text-[16px] font-[700] text-deepBlue'>{product?.name}</p>

                            <div className='mt-[20px] flex gap-[13px] items-center'>
                                <p className='text-[21px] font-[600] text-defaultBlue'>{product?.countryData?.currencyCode + " " + product?.salePrice}</p>
                                <p className='text-[12px] font-[400] text-defaultRed line-through'>{product?.countryData?.currencyCode + " " + product?.originalPrice}</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-[30px]">
                        <button className='h-[50px] rounded-[30px] border border-defaultBlue text-defaultBlue flex justify-center items-center w-full' onClick={() => navigate("/cart")}>
                            <p className='text-[16px] font-[500]'>View cart</p>
                        </button>

                        <button className='h-[50px] rounded-[30px] mt-[15px] bg-defaultBlue text-white flex justify-center items-center w-full' onClick={() => navigate("/")}>
                            <p className='text-[16px] font-[500]'>Continue shopping</p>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddedToCartDialog
