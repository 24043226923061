import React, { useEffect, useState } from 'react'
import CustomeNavbar from '../../components/layout/customeNavbar'
import CustomFooter from '../../components/layout/customFooter'
import emptycartImg from "../../images/icons/empty-cart.svg"
import { useNavigate } from 'react-router-dom'
import { FaArrowLeft, FaTrash } from 'react-icons/fa'
import { useCart } from '../../context/generalContext'
import { message, Popconfirm } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import { GetDraftedItems } from '../../functions/checkout'
import { getCookie } from '../../helpers/utils'
import { COOKIES_TOKEN_NAME } from '../../helpers/constants'

const CartPage = () => {

    const token = getCookie(COOKIES_TOKEN_NAME)
    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [hasDrafted, setHasDrafted] = useState(false)
    const [draftId, setDraftId] = useState<any>("")
    const { cart, setCart } = useCart();
    const [subtotal, setSubtotal] = useState(0);
    const taxRate = 0.0; // Assuming tax is 0%
    const shipping = 0.0

    // Calculate grand total
    const grandTotal = subtotal + (subtotal * taxRate) + shipping;

    //onload
    useEffect(() => {
        const savedCart = localStorage.getItem('cartItems');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
        // eslint-disable-next-line
    }, []);

    //getDraftedItems onload
    useEffect(() => {
        GetDraftedItems().then((response: any) => {
            if (response?.data?.payload) {
                setHasDrafted(true)
                setDraftId(response?.data?.payload?._id)
            }
        }).catch((error: any) => {
            console.log("------draftedItemsError:", error)
        })
    }, [])

    // Calculate subtotal when cart changes
    useEffect(() => {
        const newSubtotal = cart.reduce((total: any, item: any) => total + item?.salePrice * item?.itemQty, 0);
        setSubtotal(newSubtotal);
    }, [cart])

    // Function to update quantity for a specific item
    const updateQuantity = (productId: any, newQuantity: any) => {
        const updatedCart = cart.map((item: any) => {
            if (item._id === productId) {
                return { ...item, itemQty: newQuantity };
            }
            return item;
        });
        setCart(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    // Function to handle quantity increase
    const increaseQuantity = (productId: any) => {
        const currentItem = cart.find((item: any) => item._id === productId);
        if (currentItem) {
            const newQuantity = currentItem.itemQty + 1;
            updateQuantity(productId, newQuantity);
        }
    };

    // Function to handle quantity decrease
    const decreaseQuantity = (productId: any) => {
        const currentItem = cart.find((item: any) => item._id === productId);
        if (currentItem && currentItem.itemQty > 1) {
            const newQuantity = currentItem.itemQty - 1;
            updateQuantity(productId, newQuantity);
        }
    };

    //remove item from cart
    const removeFromCart = (productId: number) => {
        const updatedCart = cart.filter((item: any) => item._id !== productId);
        setCart(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };

    //handlePostCheckout
    const handlePostCheckout = async () => {
        localStorage.setItem("cartTotal", JSON.stringify({
            "subTotal": subtotal,
            "tax": taxRate,
            "shipping": shipping,
            "grandTotal": grandTotal
        }))

        const data_for_checkout = {
            "country": "65d4b85e9ae65ff72c90900e",
            "items": cart.map((item: any) => (
                {
                    "itemId": item?._id,
                    "quantity": item?.itemQty
                }
            )),
        }

        setIsBusy(true)
        try {
            const response = await BaseService.post_api(`${global_variables.checkout_route}`, data_for_checkout)
            console.log(response?.data)
            message.success("Checkout process has been initiated")
            setIsBusy(false)
            navigate(`/checkout/${response?.data?.payload?._id}`)
        } catch (error: any) {
            console.log("------checkoutError:", error)
            message.error(error?.response?.data?.error || "An error occured. Please retry")
            setIsBusy(false)
        }
    }

    //handleUpdateCheckout
    const handleUpdateCheckout = async () => {
        localStorage.setItem("cartTotal", JSON.stringify({
            "subTotal": subtotal,
            "tax": taxRate,
            "shipping": shipping,
            "grandTotal": grandTotal
        }))

        const data_for_update = {
            "_id": draftId,
            "items": cart.map((item: any) => (
                {
                    "itemId": item?._id,
                    "quantity": item?.itemQty
                }
            )),
        }

        setIsBusy(true)
        try {
            const response = await BaseService.put_api(`${global_variables.checkout_route}`, data_for_update)
            console.log(response?.data)
            message.success("Checkout process has been initiated")
            setIsBusy(false)
            navigate(`/checkout/${response?.data?.payload?._id}`)
        } catch (error: any) {
            console.log("------checkoutError:", error)
            message.error(error?.response?.data?.error || "An error occured. Please retry")
            setIsBusy(false)
        }
    }

    //handleProceedToCheckout
    const handleProceedToCheckout = () => {
        if (token) {
            if (hasDrafted) {
                console.log("Draft dey so we update existing")
                handleUpdateCheckout()
            } else {
                console.log("Draft no dey so we create new")
                handlePostCheckout()
            }
        } else {
            message.info("Sign into your account proceed.")
        }
    }

    //handleCancelCheckout
    const handleCancelCheckout = async () => {
        try {
            const response = await BaseService.patch_api(`${global_variables.checkout_route}/status-cancel/${draftId}`)
            console.log("------cancelCheckoutSuccess", response)
            message.success("Cart has been cleared.")
            setIsBusy(false)
            localStorage.removeItem("cartItems")
            localStorage.removeItem("cartTotal")
            setCart([])
        } catch (error: any) {
            console.log("------cancelCheckoutError:", error)
            message.error(error?.response?.data?.error || "An error occured. Please retry")
            setIsBusy(false)
        }
    }


    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* navbar */}
                <CustomeNavbar />

                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] my-[40px]'>
                        <div className='flex gap-6 items-center'>
                            <button className='text-deepBlue flex gap-2 items-center' onClick={() => navigate("/")}>
                                <FaArrowLeft className='h-4 w-4' />
                                <p>Back</p>
                            </button>
                            <p className='text-[30px] font-[600] text-deepBlue'>Shopping cart</p>
                        </div>

                        <div className="mt-10">
                            {
                                cart.length === 0 ?
                                    <div className='flex flex-col items-center'>
                                        <p>Your cart is empty!</p>

                                        <img className='flex mr-auto ml-auto' src={emptycartImg} alt="cart" />

                                        <button className='h-[50px] rounded-[30px] mt-[30px] bg-defaultBlue text-white flex justify-center items-center w-[300px]' onClick={() => navigate("/")}>
                                            <p className='text-[16px] font-[500]'>Go to shop</p>
                                        </button>
                                    </div>
                                    :
                                    cart.map((item: any, i: number) => (
                                        <div key={i} className='w-full xl:flex lg:flex md:flex justify-between pb-[17px] border-b-[1px] border-b-[#C2C2F7] mb-5'>
                                            <div className='flex gap-[18px] items-center'>
                                                <img className='h-[97px] w-[140px] rounded-[10px] object-cover' src={item?.images[0] || ""} alt="item" />

                                                <div className='text-deepBlue'>
                                                    <p className='text-[16px] font-[700]'>{item?.name}</p>
                                                    <p className='text-[16px] font-[400] mt-[4px] w-[200px] line-clamp-2'>
                                                        {item?.description || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex volupt"}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='flex gap-[70px] items-center justify-between mt-3 lg:mt-0'>
                                                <div>
                                                    <p className='lg:text-[16px] text-[12px] font-[700] text-deepBlue'>Quantity</p>

                                                    <div className="flex border-[1px] border-[#C2C2F7] h-[40px] w-[120px] mt-[4px]">
                                                        <button className="w-[40px] h-[40px] disabled:cursor-not-allowed" onClick={() => decreaseQuantity(item._id)} disabled={isBusy}>-</button>
                                                        <input className="w-[40px] focus:outline-none border-x-[1px] border-[#C2C2F7] flex items-center justify-center text-center disabled:cursor-not-allowed" value={item?.itemQty} disabled />
                                                        <button className="w-[40px] h-[40px] disabled:cursor-not-allowed" onClick={() => increaseQuantity(item._id)} disabled={isBusy}>+</button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p className='text-[21px] font-[600] text-deepBlue'>{item?.countryData?.currencyCode + " " + item?.salePrice}</p>
                                                </div>

                                                <div>
                                                    <Popconfirm
                                                        title="Remove this item from cart?"
                                                        description="This action cannot be reversed."
                                                        placement="left"
                                                        onConfirm={() => removeFromCart(item._id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        okButtonProps={{ className: 'bg-defaultBlue text-white' }}
                                                    >
                                                        <button className='h-5 w-5 text-deepBlue hover:text-red-500 disabled:cursor-not-allowed' disabled={isBusy}>
                                                            <FaTrash />
                                                        </button>
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }

                            {/* total card */}
                            {
                                cart.length !== 0 &&
                                <div className='flex justify-end mt-[30px]'>
                                    <div className='w-[300px]'>
                                        <div className='bg-[#F2F2FE] rounded-[20px] p-[25px] text-[#3A393E]'>
                                            <div className="flex justify-between items-center mt-[10px]">
                                                <p className='text-[15px] font-[500]'>Sub total</p>
                                                <p className='text-[15px] font-[500]'>GHS {subtotal.toFixed(2)}</p>
                                            </div>
                                            <div className="flex justify-between items-center mt-[10px]">
                                                <p className='text-[15px] font-[500]'>Tax</p>
                                                <p className='text-[15px] font-[500]'>GHS {(subtotal * taxRate).toFixed(2)}</p>
                                            </div>
                                            <div className="flex justify-between items-center mt-[10px]">
                                                <p className='text-[15px] font-[500]'>Shipping</p>
                                                <p className='text-[15px] font-[500]'>GHS {shipping.toFixed(2)}</p>
                                            </div>
                                            <div className="flex justify-between items-center mt-[10px] text-defaultRed">
                                                <p className='text-[18px] font-[700]'>Grand Total</p>
                                                <p className='text-[18px] font-[700]'>GHS {grandTotal.toFixed(2)}</p>
                                            </div>
                                        </div>

                                        <Popconfirm
                                            title="Cancel checkout process?"
                                            description="All items will be cleared from your cart."
                                            onConfirm={() => handleCancelCheckout()}
                                            okText="Yes"
                                            cancelText="No"
                                            okButtonProps={{ className: 'bg-defaultBlue text-white' }}
                                        >
                                            <button className='mt-[30px] border border-red-500 text-red-500 rounded-[40px] h-[50px] w-full disabled:cursor-not-allowed' disabled={isBusy}>

                                                <p className='text-[16px] font-[400]'>Cancel</p>
                                            </button>
                                        </Popconfirm>

                                        <button className='mt-[10px] bg-defaultBlue text-white rounded-[40px] h-[50px] w-full disabled:cursor-not-allowed' onClick={handleProceedToCheckout} disabled={isBusy}>
                                            {
                                                isBusy ?
                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                    :
                                                    <p className='text-[16px] font-[400]'>Check Out</p>
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {/* footer */}
                    <div>
                        <CustomFooter />
                    </div>
                </div>
            </div>

        </>
    )
}

export default CartPage
