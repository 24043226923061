import React, { useState } from 'react'
import PinInput from 'react-pin-input';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import Lottie from "lottie-react";
import otpAnimation from "../../animations/otp-animation.json"
import VerifyTransaction from './verifyTransaction';


interface theProps {
    response: any
    handleClose: () => void
}


const OtpVerification = ({ response, handleClose }: theProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [otpResponse, setOtpResponse] = useState<any>(null)

    //handleOTPVerification
    const handleVerification = async (theCode: any) => {
        const otp_info = {
            "otp": theCode,
            "reference": response?.transaction?.reference
        }

        setIsBusy(true)

        try {
            const response = await BaseService.post_api(global_variables.verify_transaction, otp_info)
            console.log("depositRes:", response?.data)
            setOtpResponse(response?.data?.payload)
            setIsBusy(false)
        } catch (error: any) {
            console.log("verifyErr:", error)
            message.error(error?.response?.data?.error || "An error occured. Please try again")
            setIsBusy(false)
            handleClose()
        }
    }


    return (
        <>
            {
                !otpResponse ?
                    <>
                        <Spin spinning={isBusy} indicator={<LoadingOutlined style={{ fontSize: 52, color: 'black' }} spin />}>
                            <p className='text-lg font-semibold'>Phone Number Verification</p>
                            <hr />

                            <p className='mt-3 text-center text-lg'>{response?.transaction?.display_text || "Please enter the one-time password sent to your phone"}</p>

                            <div className="mt-3 w-[200px] flex ml-auto mr-auto">
                                <Lottie animationData={otpAnimation} loop={true} />
                            </div>

                            <div className='flex justify-center mt-5'>
                                <PinInput
                                    length={6}
                                    secret
                                    secretDelay={100}
                                    // onChange={(value, index) => { }}
                                    type="numeric"
                                    inputMode="number"
                                    style={{ padding: '5px' }}
                                    inputStyle={{ borderColor: '#6666EA', borderRadius: '10px', height: '60px', width: '60px' }}
                                    inputFocusStyle={{ borderColor: '#15C2A5' }}
                                    onComplete={(value, index) => handleVerification(value)}
                                    autoSelect={true}
                                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                    disabled={isBusy}
                                />
                            </div>

                            <div className='flex justify-center mt-5'>
                                <button className='text-red-500' onClick={handleClose}>
                                    Cancel Transaction
                                </button>
                            </div>
                        </Spin>
                    </>
                    :
                    <>
                        <VerifyTransaction response={otpResponse} handleClose={handleClose} />
                    </>
            }
        </>
    )
}

export default OtpVerification
