import BaseService from "../helpers/baseServices";
import { global_variables } from "../helpers/globalVariables";

export const GetDraftedItems = async () => {
  try {
    const response = await BaseService.get_api(
      `${global_variables.checkout_route}/status-drafted`
    );
    return response;
  } catch (error) {
    return error;
  }
};
