const route = "/robocentre";

export const global_variables = {
  //general
  get_all_contries: `${route}/country`,

  // auth url
  signin_route: `${route}/auth/signin`,
  signup_route: `${route}/auth/signup`,
  verify_account: `${route}/auth/verify_account`,
  forgot_password: `${route}/auth/forgetpassword`,
  reset_password: `${route}/auth/resetpassword`,

  //ecommerce
  product_categories: `${route}/product-category`,
  all_products: `${route}/product`,
  private_products: `${route}/product/private`,
  checkout_route: `${route}/sales`,
  create_purchase: `${route}/purchase`,
  save_delivery_info: `${route}/delivery-information`,

  //wallet & transactions
  get_user_transactions: `${route}/transaction`,
  get_user_wallet: `${route}/transaction/wallet`,
  initiate_deposit: `${route}/transaction/momo-deposit`,
  verify_transaction: `${route}/transaction/otp-verification`,
  manual_transaction_verify: `${route}/transaction/manual-verification`,

  //wishlist
  add_to_wishlist: `${route}/product-wishlist`,
  remove_from_wishlist: `${route}/product-wishlist`,
  get_all_wishlist: `${route}/product-wishlist`,

  //user
  get_user_details: `${route}/user/details`,
  update_user_details: `${route}/user/details`,
};
