import React from 'react'
import CustomeNavbar from '../../components/layout/customeNavbar'
import SearchBar from '../../components/shared/searchBar';
import freeShipping from "../../images/icons/freeShipping.svg"
import supportImg from "../../images/icons/support.svg"
import moneBackImg from "../../images/icons/moneyBack.svg"
import secureImg from "../../images/icons/secure.svg"
import BannerSlider from '../../components/shared/bannerSlider';
import ProductsInStore from './productsInStore';
import BestSellerProducts from './bestSellerProducts';
import kidsLearnImg from "../../images/kidsLearn.png"
import roboLogoImg from "../../images/roboLogo1.png"
import kidsImg from "../../images/kidsImg.jpg"
import CustomFooter from '../../components/layout/customFooter';
import SubscriptionCard from '../../components/shared/subscriptionCard';
import ProductCategoryComponent from './productCategoryComponent';


const ShopHome = () => {

    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* navbar */}
                <CustomeNavbar />

                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='w-full px-5 lg:px-0 flex justify-center mt-[10px]'>
                        <SearchBar />
                    </div>

                    <div className='2xl:px-[180px] xl:mx-[63px] lg:mx-[63px] md:mx-[30px] mx-5 mt-[10px]'>
                        {/* banner */}
                        <div className='xl:grid lg:grid md:grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-x-[16px]'>
                            {/* left */}
                            <div className="w-full">
                                <ProductCategoryComponent />
                            </div>

                            {/* right */}
                            <div className="col-span-2 xl:h-[413px] lg:h-[413px] md:h-[413px] h-[413px]">
                                <BannerSlider />
                            </div>
                        </div>

                        {/* features */}
                        <div className="mt-[24px] lg:px-[90px] px-5 xl:py-[44px] lg:py-[44px] md:py-[30px] bg-white shadow-sm rounded-[24px] xl:grid lg:grid md:grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xl:gap-x-[78px] lg:gap-x-[60px] md:gap-x-[40px]">
                            <div className='flex gap-[17px] w-full mb-5 xl:mb-0 lg:mb-0 md:mb-0'>
                                <div>
                                    <img src={freeShipping} alt="" />
                                </div>

                                <div>
                                    <p className='xl:text-[21px] lg:text-[21px] md:text-[18px] text-[16px] font-[600] text-deepBlue'>Free Shipping</p>
                                    <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-[14px] mt-[4px] font-400 text-deepBlue'>Free Shipping over $100</p>
                                </div>
                            </div>

                            <div className='flex gap-[17px] w-full mb-5 lg:mb-0 md:mb-0'>
                                <div>
                                    <img src={secureImg} alt="" />
                                </div>

                                <div>
                                    <p className='xl:text-[21px] lg:text-[21px] md:text-[18px] text-[16px] font-[600] text-deepBlue'>Secure Payment</p>
                                    <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-[14px] mt-[4px] font-400 text-deepBlue'>100% secure payment</p>
                                </div>
                            </div>

                            <div className='flex gap-[17px] w-full mb-5 xl:mb-0 lg:mb-0 md:mb-0'>
                                <div>
                                    <img src={moneBackImg} alt="" />
                                </div>

                                <div>
                                    <p className='xl:text-[21px] lg:text-[21px] md:text-[18px] text-[16px] font-[600] text-deepBlue'>Money Return</p>
                                    <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-[14px] mt-[4px] font-400 text-deepBlue'>30 days for free return</p>
                                </div>
                            </div>

                            <div className='flex gap-[17px] w-full'>
                                <div>
                                    <img src={supportImg} alt="" />
                                </div>

                                <div>
                                    <p className='xl:text-[21px] lg:text-[21px] md:text-[18px] text-[16px] font-[600] text-deepBlue'>24/7 Support</p>
                                    <p className='xl:text-[16px] lg:text-[16px] md:text-[14px] text-[14px] mt-[4px] font-400 text-deepBlue'>Support 24 hours a day</p>
                                </div>
                            </div>
                        </div>

                        {/* products in store */}
                        <div className="mt-[80px] xl:mx-[150px] lg:mx-[100px] md:mx-[50px] mx-3">
                            <ProductsInStore />
                        </div>

                        {/* best seller products */}
                        <div className="mt-[80px] xl:mx-[150px] lg:mx-[100px] md:mx-[50px] mx-3">
                            <BestSellerProducts />
                        </div>
                    </div>

                    {/* where kids learn */}
                    <div className="2xl:grid xl:grid lg:grid md:grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 items-center gap-[62px] mt-[150px] bg-white py-[90px] px-[43px] 2xl:px-[150px] w-full">
                        <div>
                            <img className='xl:w-[440px] lg:w-[440px] md:w-[300px] w-[300px] flex mr-auto ml-auto' src={kidsLearnImg} alt="kids" />
                        </div>

                        <div className='flex flex-col justify-center'>
                            <img className='w-[280px]' src={roboLogoImg} alt="robLogo" />

                            <div className='mt-[40px] pr-[43px]'>
                                <p className='xl:text-[42px] lg:text-[42px] md:text-[32px] text-[24px] font-[600] text-deepBlue leading-[42px]'>
                                    Where Kids & Teens Learn To Become Innovators
                                </p>

                                <p className='text-[16px] font-[400] text-deepBlue mt-[40px]'>
                                    We help Parents turn their children's interests into real-world STEM
                                    skills. Our curriculum is developed to empower children to become
                                    the future scientist, engineers, inventors and entrepreneurs.
                                </p>

                                <button className='lg:px-[76px] px-[40px] py-[16px] rounded-[50px] bg-defaultBlue text-white mt-[40px]'>
                                    <p className='lg:text-[21px] text-[14px] font-[600]'>Explore our courses</p>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* kids playing */}
                    <div className='w-full relative'>
                        <img className='w-full relative' src={kidsImg} alt="kidsPlay" />

                        <div className='absolute left-0 right-0 2xl:top-[400px] xl:top-[200px] lg:top-[120px] md:top-[90px] top-[20px] px-5 lg:px-0'>
                            <SubscriptionCard />
                        </div>
                    </div>

                    {/* footer */}
                    <div>
                        <CustomFooter />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShopHome
