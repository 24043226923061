import BaseService from "../helpers/baseServices"
import { global_variables } from "../helpers/globalVariables"

export const getWalletData = async () => {

    try {
        const response = await BaseService.get_api(`${global_variables.get_user_wallet}/65d4b85e9ae65ff72c90900e`)
        console.log("userWalletRes:", response?.data)
        return response?.data?.payload
    } catch (error) {
        return error
    }
}