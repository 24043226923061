import React, { useState } from 'react'
import { Input, message, Spin } from 'antd'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { StorageBox } from '../../helpers/stroage';

interface theProps {
    handleClose: () => void
}

const SignIn = ({ handleClose }: theProps) => {

    const [theEmail, setTheEmail] = useState("")
    const [thePwd, setThePwd] = useState("")

    const [pwdVisible, setPwdVisible] = useState(false)
    const [isBusy, setIsBusy] = useState(false)


    //handleContinue
    const handleContinue = async () => {
        if (!theEmail || !thePwd) {
            message.warning("Please fill out all fields")
        } else {
            const auth_data = {
                "email": theEmail,
                "password": thePwd
            }

            setIsBusy(true)

            try {
                const response = await BaseService.post_api(global_variables.signin_route, auth_data)
                console.log("signinRes:", response?.data)
                StorageBox.saveAccessToken(response?.data?.token)
                StorageBox.saveUserData(response?.data?.payload)
                message.success(`Logged in as ${response?.data?.payload?.firstName || "Unknown"}`)
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            } catch (error: any) {
                console.log("signinError:", error)
                message.error(error?.response?.data?.error || "An unexpected error occured. Please try again")
                setIsBusy(false)
            }
        }
    }


    return (
        <>
            <div className='w-full text-deepBlue'>
                <p className='text-[21px] font-[400] text-center'>Sign in to your Account</p>

                <div className="mt-[32px] w-full">
                    <label htmlFor='email' className='text-[16px] font-[400]'>Email <span className='text-red-500'>*</span></label>
                    <Input id='email' className='rounded-[8px] h-[52px]' value={theEmail} onChange={(e) => setTheEmail(e.target.value)} placeholder='Eg: johndoe@gmail.com' disabled={isBusy} />
                </div>

                <div className="mt-[16px] w-full relative">
                    <label htmlFor='pwd' className='text-[16px] font-[400]'>Password <span className='text-red-500'>*</span></label>
                    <Input id='pwd' className='rounded-[8px] h-[52px]' value={thePwd} onChange={(e) => setThePwd(e.target.value)} type={pwdVisible ? 'text' : 'password'} placeholder='Eg: 123456' disabled={isBusy} />

                    {
                        pwdVisible ?
                            <FaEyeSlash className='h-5 w-5 cursor-pointer text-gray-500 absolute right-3 bottom-4' onClick={() => setPwdVisible(false)} />
                            :
                            <FaEye className='h-5 w-5 cursor-pointer text-gray-500 absolute right-3 bottom-4' onClick={() => setPwdVisible(true)} />
                    }
                </div>

                <div className="mt-[16px] w-full flex justify-end">
                    <button className='text-[16px] font-[400] text-defaultBlue' disabled={isBusy}>
                        Forgot your password?
                    </button>
                </div>

                <div className="mt-[32px] w-full">
                    <button className='w-full h-[63px] rounded-[50px] bg-defaultBlue text-white disabled:cursor-pointer' disabled={isBusy} onClick={handleContinue}>
                        {
                            isBusy ? <Spin indicator={<LoadingOutlined style={{ fontSize: 26, color: '#fff' }} spin />} />
                                :
                                "Continue"
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default SignIn
