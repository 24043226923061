import React, { useState } from 'react'
import { Drawer } from 'antd'
import { Link } from 'react-router-dom'
import AuthenticationModal from '../../pages/authentication/authenticationModal'


interface drawerProps {
    isOpened: boolean
    handleClose: () => void
    menuList: any
}


const MobileNav = ({ isOpened, handleClose, menuList }: drawerProps) => {

    //auth modal states
    const [activeTab, setActiveTab] = useState<any>(0)
    const [openAuthModal, setOpenAuthModal] = useState(false)
    const handleOpenAuthModal = () => {
        setOpenAuthModal(true)
    }
    const handleCloseAuthModal = () => {
        setOpenAuthModal(false)
    }

    return (
        <>
            <Drawer width={250} title={null} open={isOpened} onClose={handleClose}>
                <div className='flex flex-col gap-[20px]'>
                    {
                        menuList.map((item: any, i: number) => (
                            <Link key={i} to={item.link}>
                                <p className='text-[16px] font-[500]'>{item.name}</p>
                            </Link>
                        ))
                    }
                </div>

                <hr className='mt-5' />

                <div className='mt-5 flex flex-col w-full gap-5'>
                    <button className='border border-defaultBlue rounded-[30px] px-[16px] py-[6px] text-defaultBlue' onClick={() => {
                        handleOpenAuthModal()
                        setActiveTab(1)
                    }}>
                        <p className='text-[16px] font-[600]'>Login</p>
                    </button>

                    <button className='px-[16px] py-[6px] bg-defaultBlue text-white rounded-[30px]' onClick={() => {
                        handleOpenAuthModal()
                        setActiveTab(0)
                    }}>
                        <p className='text-[16px] font-[500]'>Sign up</p>
                    </button>
                </div>
            </Drawer>


            {/* openAuthModal */}
            {
                openAuthModal && <AuthenticationModal isOpened={openAuthModal} handleClose={handleCloseAuthModal} tabNumber={activeTab} />
            }
        </>
    )
}

export default MobileNav
