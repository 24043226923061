import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import mingoLogo from "../../images/mingoLogoBlue.svg"
import BaseService from '../../helpers/baseServices'
import { Spin, Table } from 'antd'
import moment from 'moment'
import { calculateTotalAmount } from '../../utils/getInvoiceSum'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { HiOutlineArrowDownTray } from "react-icons/hi2";



const InvoicePage = () => {

    const { id } = useParams()
    const [isBusy, setIsBusy] = useState(false)
    const [invoiceData, setInvoiceData] = useState<any>(null)

    //getOneInvoice
    const getOneInvoice = async () => {
        setIsBusy(true)

        try {
            const response = await BaseService.get_api(`/robocentre/sales/${id}`)
            console.log("invoiceData:", response?.data)
            setInvoiceData(response?.data?.payload)
            setIsBusy(false)
        } catch (error) {
            console.log(error)
        }
    }

    //onlaod
    useEffect(() => {

        if (id) {
            getOneInvoice()
            localStorage.removeItem("cartItems")
            localStorage.removeItem("cartTotal")
        }

        // eslint-disable-next-line
    }, [id])

    //items purchased table columns
    const columns = [
        {
            title: 'Description',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Unit Cost',
            dataIndex: 'amount',
            key: 'amount',
            render: (text: number) => `${invoiceData?.countryData?.currencyCode} ${text}`,
        },
    ];

    //save to pdf
    const printDocument = () => {
        const input: any = document.getElementById('pdf');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 210; // A4 width in mm
                const imgHeight = canvas.height * imgWidth / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save("robocentre-invoice.pdf");
            });
    };


    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                <div className='h-full overflow-y-auto'>
                    <div className="px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] lg:py-[70px] py-5">
                        <div className='flex justify-between items-center'>
                            <button className='text-defaultBlue' onClick={() => window.location.href = "/"}>
                                <p className='text-[16px] font-[700]'>Back to home</p>
                            </button>

                            <button className='flex gap-2 items-center text-defaultBlue font-[600]' onClick={printDocument}>
                                <p>Save</p>
                                <HiOutlineArrowDownTray className='h-5 w-5' />
                            </button>
                        </div>

                        {/* main */}
                        <Spin spinning={isBusy}>
                            <div className="pt-[30px] py-60 px-[60px] bg-white shadow-sm rounded-[10px] mt-[25px] text-deepBlue relative" id="pdf">
                                <img src={mingoLogo} alt="logo" />

                                {/* basic info */}
                                <div className="mt-5 2xl:flex xl:flex lg:flex md:flex justify-between">
                                    <div className='w-full'>
                                        <p className='text-[16px] font-[700]'>Invoice to</p>

                                        <p className='text-[12px] font-[500] mt-[4px] text-[#9D9CC2]'>{invoiceData?.owner?.fullName}</p>
                                        <p className='text-[12px] font-[500] text-[#9D9CC2]'>{invoiceData?.deliveryData?.placeName}</p>
                                        <p className='text-[12px] font-[500] text-[#9D9CC2]'>{invoiceData?.deliveryData?.city}</p>
                                        <p className='text-[12px] font-[500] text-[#9D9CC2] mt-5'>{invoiceData?.owner?.phone}</p>
                                    </div>
                                    <div className='w-full mt-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 2xl:text-right xl:text-right lg:text-right md:text-right'>
                                        <p className='text-[16px] font-[700]'>Payment to</p>

                                        <p className='text-[12px] font-[500] mt-[4px] text-[#9D9CC2]'>Mingoblox LLC</p>
                                        <p className='text-[12px] font-[500] text-[#9D9CC2]'>Presidential Floor, Mövenpick Ambassador Hotel</p>
                                        <p className='text-[12px] font-[500] text-[#9D9CC2]'>Accra Ghana</p>
                                    </div>
                                </div>

                                {/* date & payment method */}
                                <div className="mt-10 2xl:flex xl:flex lg:flex md:flex justify-between">
                                    <div className='w-full'>
                                        <p className='text-[16px] font-[700]'>Invoice date/ time</p>

                                        <p className='text-[12px] font-[500] mt-[4px] text-[#9D9CC2]'>{moment(invoiceData?.updatedAt).format("DD/MM/YYYY - h:mm A")}</p>
                                    </div>
                                    <div className='w-full mt-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 2xl:text-right xl:text-right lg:text-right md:text-right'>
                                        <p className='text-[16px] font-[700]'>Payment Method</p>

                                        <p className='text-[12px] font-[500] mt-[4px] text-[#9D9CC2]'>Credit Card</p>
                                    </div>
                                </div>

                                {/* invoice items */}
                                <div className="mt-10">
                                    <p className='text-[30px] font-[600]'>Items Purchased</p>

                                    <Table
                                        className="mt-5"
                                        columns={columns}
                                        dataSource={invoiceData?.items}
                                        pagination={false}
                                        rowKey={(record) => record.name}
                                        summary={(pageData) => {
                                            // eslint-disable-next-line
                                            let totalAmount = 0;
                                            pageData.forEach(({ amount }) => {
                                                totalAmount += amount;
                                            });
                                            return (
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0} colSpan={2} className='text-[16px] font-[700] text-defaultBlue'>
                                                        Grand Total
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1} className='text-[16px] font-[700] text-defaultBlue'>
                                                        {invoiceData?.countryData?.currencyCode} {calculateTotalAmount(invoiceData?.items)}
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            );
                                        }}
                                    />
                                </div>

                                {/* footer */}
                                <div className='lg:flex lg:gap-[90px] absolute bottom-5'>
                                    <div className='text-[#9D9CC2]'>
                                        <p className='text-[12px] font-[500]'>+233 (0)24 444 6002</p>
                                        <p className='text-[12px] font-[500]'>www.mingoblox.com</p>
                                    </div>

                                    <div className='text-[#9D9CC2]'>
                                        <p className='text-[12px] font-[500]'>info@mingoblox.com</p>
                                        <p className='text-[12px] font-[500]'>Presidential Floor, Movenpick Ambassador Hotel, Accra</p>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoicePage
