import React, { useEffect, useState } from 'react'
import { Input, message, Spin, Steps } from 'antd'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import PhoneInput, { Value } from "react-phone-number-input";
import { useCart } from '../../../context/generalContext';
import BaseService from '../../../helpers/baseServices';
import LocationSelector from '../../../components/shared/locationSelector';
import { handleLocationDecode } from '../../../functions/decodeLocation';
import { global_variables } from '../../../helpers/globalVariables';
import { LoadingOutlined } from '@ant-design/icons';


const CheckoutPage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { cart, setCart } = useCart();
    const [cartTotals, setCartTotals] = useState<any>(null)
    const [enterAddress, setEnterAddress] = useState(false)

    const [currStep, setCurrStep] = useState(0)

    const [isBusy, setIsBusy] = useState(false)
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNum, setPhoneNum] = useState<Value | undefined>("")
    const [placeName, setPlaceName] = useState("")
    const [long, setLong] = useState<any>("")
    const [lat, setlat] = useState<any>("")
    const [theCity, setTheCity] = useState("")
    const [theRegion, setTheRegion] = useState("")

    //sales states
    const [salesData, setSalesData] = useState<any>(null)


    //getOneSale
    const getOneSale = async () => {
        try {
            const response = await BaseService.get_api(`/robocentre/sales/${id}`)
            console.log("oneSalesRes:", response?.data)
            setSalesData(response?.data?.payload)
            setFName(response?.data?.payload?.owner?.firstName)
            setLName(response?.data?.payload?.owner?.lastName)
            setPhoneNum(response?.data?.payload?.owner?.phone)
            setEmail(response?.data?.payload?.owner?.email)
        } catch (error) {
            console.log("oneSaleErr:", error)
        }
    }

    //onload
    useEffect(() => {
        const savedCart = localStorage.getItem('cartItems');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }

        const fromStorage = localStorage.getItem("cartTotal" ?? null)
        if (fromStorage) {
            setCartTotals(JSON.parse(fromStorage))
        }

        setCurrStep(0)
        getOneSale()

        // eslint-disable-next-line
    }, [])


    // handle location slected
    const handleLocationSlected = (selected: any) => {
        if (selected) {
            console.log(selected.value);
            handleLocationDecode(selected).then((response) => {
                const { placeName, region, city, latitude, longitude } = response;
                setTheCity(city)
                setTheRegion(region)
                setPlaceName(placeName);
                setLong(longitude)
                setlat(latitude)
            }).catch((error: any) => {
                console.error("An unexpected error occurred:", error);
            });
        }
    };

    //handleUpdateCheckout
    const handleUpdateCheckout = async () => {
        const data_for_update = {
            "_id": id,
            "items": salesData?.items.map((item: any) => (
                {
                    "itemId": item?.itemId,
                    "quantity": item?.quantity
                }
            )),
            "owner": {
                "firstName": fName,
                "lastName": lName,
                "fullName": fName + " " + lName,
                "phone": phoneNum,
                "email": email
            }
        }

        setIsBusy(true)
        try {
            const response = await BaseService.put_api(`${global_variables.checkout_route}`, data_for_update)
            console.log("updateCheckRes:", response?.data)
        } catch (error: any) {
            console.log("------checkoutError:", error)
            message.error(error?.response?.data?.error || "An error occured. Please retry")
            setIsBusy(false)
        }
    }


    //handleContinue
    const handleContinue = async () => {
        if (!fName) {
            message.warning("First name cannot be empty")
        } else if (!lName) {
            message.warning("Last name cannot be empty")
        } else if (!phoneNum) {
            message.warning("Enter your phone number")
        } else if (!email) {
            message.warning("Please enter your email")
        } else if (!placeName || !theCity || !theRegion) {
            message.warning("Enter your delivery location, region and city")
        } else {
            handleUpdateCheckout().then(() => {
                let delivery_data = {
                    "salesId": id,
                    "delivery": {
                        "placeName": placeName,
                        "region": theRegion,
                        "city": theCity,
                        "latitude": lat,
                        "longitude": long,
                        "deliveryContact": phoneNum
                    }
                }

                if (!lat) {
                    delete delivery_data.delivery.latitude;
                }
                if (!long) {
                    delete delivery_data.delivery.longitude;
                }

                BaseService.post_api(`/robocentre/sales/delivery-information`, delivery_data)
                    .then((response) => {
                        console.log("deliveryRes:", response)
                        message.success("Shipping address has been saved")
                        navigate(`/payment/${id}`)
                    }).catch((error: any) => {
                        setIsBusy(false)
                        console.log("deliveryErr:", error)
                        message.error(error?.response?.data?.error || "An error occured. Please try again")
                    })
            })
        }
    }

    //handleAddressCheck
    const handleAddressCheck = (value: boolean) => {
        setEnterAddress(value)
    }


    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] mt-[25px] text-deepBlue'>
                        <p className='text-[32px] font-[700] text-center'>Shipping</p>

                        <div className='mt-5'>
                            <Steps
                                current={0}
                                progressDot
                                items={[
                                    {
                                        title: 'Your Address',
                                        status: `${currStep === 0 ? 'process' : 'finish'}`
                                    },
                                    {
                                        title: 'Payment',
                                        status: `${currStep === 1 ? 'process' : 'wait'}`
                                    },
                                ]}
                            />
                        </div>

                        <div className="2xl:grid xl:grid lg:grid md:grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-[25px] w-full mt-[24px] pb-10">
                            {/* left */}
                            <div className='xl:col-span-2 lg:col-span-2 w-full'>
                                <div className='w-full bg-white border border-[#CCCCF8] px-5 pt-5 pb-[50px] rounded-[10px] 2xl:h-[500px] xl:h-[500px] lg:h-[500px] md:h-[500px] h-[750px]'>
                                    <p className='text-[16px] font-[700]'>Shipping Address</p>

                                    <div className="mt-[26px] lg:grid lg:grid-cols-2 gap-5 w-full">
                                        <div>
                                            <label htmlFor="fName" className='text-[12px] font-[500] text-[#9D9CC2]'>First Name</label>
                                            <Input className='w-full h-[40px]' value={fName} onChange={(e) => setFName(e.target.value)} id="fName" placeholder='E.g: John' disabled={isBusy} />
                                        </div>

                                        <div className='mt-5 md:mt-5 lg:mt-0 xl:mt-0'>
                                            <label htmlFor="lName" className='text-[12px] font-[500] text-[#9D9CC2]'>Last Name</label>
                                            <Input className='w-full h-[40px]' value={lName} onChange={(e) => setLName(e.target.value)} id='lName' placeholder='E.g: Doe' disabled={isBusy} />
                                        </div>

                                        <div className='mt-5 md:mt-5 lg:mt-0 xl:mt-0'>
                                            <label htmlFor="phone" className='text-[12px] font-[500] text-[#9D9CC2]'>Phone</label>
                                            <PhoneInput
                                                className="rounded-[5px] w-full h-[40px] border border-gray-300 px-2"
                                                defaultCountry="GH"
                                                value={phoneNum}
                                                onChange={(phone) => setPhoneNum(phone)}
                                                placeholder="E.g: +233247360007"
                                                numberInputProps={{
                                                    className: "focus:outline-none disabled:cursor-not-allowed rounded-[50px] px-3"
                                                }}
                                                disabled={isBusy}
                                            />
                                        </div>

                                        <div className='mt-5 md:mt-5 lg:mt-0 xl:mt-0'>
                                            <label htmlFor="mail" className='text-[12px] font-[500] text-[#9D9CC2]'>Email</label>
                                            <Input className='w-full h-[40px]' value={email} onChange={(e) => setEmail(e.target.value)} id='mail' type='email' placeholder='E.g: johndoe@gmail.com' disabled={isBusy} />
                                        </div>

                                        <div className='mt-5 md:mt-5 lg:mt-0 xl:mt-0'>
                                            <div>
                                                {
                                                    enterAddress ?
                                                        <>
                                                            <div className="h-[40px] w-full">
                                                                <label htmlFor="place" className='text-[12px] font-[500] text-[#9D9CC2]'>Delivery Address</label>
                                                                <Input id='place' className='w-full h-[40px]' value={placeName} onChange={(e) => setPlaceName(e.target.value)} placeholder='Enter delivery address' disabled={isBusy} />
                                                            </div>

                                                            <div className="h-[40px] w-full mt-5">
                                                                <label htmlFor="region" className='text-[12px] font-[500] text-[#9D9CC2]'>Region</label>
                                                                <Input id='region' className='w-full h-[40px]' value={theRegion} onChange={(e) => setTheRegion(e.target.value)} placeholder='Enter address region' disabled={isBusy} />
                                                            </div>

                                                            <div className="h-[40px] w-full mt-5">
                                                                <label htmlFor="city" className='text-[12px] font-[500] text-[#9D9CC2]'>City</label>
                                                                <Input id='city' className='w-full h-[40px]' value={theCity} onChange={(e) => setTheCity(e.target.value)} placeholder='Enter address city' disabled={isBusy} />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="h-[40px] w-full">
                                                            <label htmlFor="place" className='text-[12px] font-[500] text-[#9D9CC2]'>Delivery Address</label>
                                                            <LocationSelector placeholder='Type to search' onChange={(selected) => handleLocationSlected(selected)} defaultInputValue={placeName} disabled={isBusy} />
                                                        </div>

                                                }

                                                <div className="mt-8">
                                                    <div className="flex gap-2 items-center">
                                                        <input className='h-5 w-5' id="other" type="checkbox" onChange={(e) => handleAddressCheck(e.target.checked)} />
                                                        <label htmlFor="other" className='text-lg'>Can't find my address</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-5 md:mt-5 lg:mt-0 xl:mt-0'>
                                            <label htmlFor="desc" className='text-[12px] font-[500] text-[#9D9CC2]'>Address Description (Optional)</label>
                                            <div className="h-[40px] w-full">
                                                <Input.TextArea id="desc" className='w-full' style={{ height: 150 }} disabled={isBusy} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-[30px] flex justify-between items-center">
                                    <button className='flex gap-[10px] items-center text-gray-600 border border-gray-600 rounded-[40px] h-[45px] px-8 disabled:cursor-not-allowed' onClick={() => navigate("/cart")} disabled={isBusy}>
                                        <FaArrowLeft className='h-4 w-4' />
                                        <p>Back</p>
                                    </button>

                                    <button className='h-[45px] text-white border bg-defaultBlue rounded-[40px] px-8 disabled:cursor-not-allowed' onClick={handleContinue} disabled={isBusy}>
                                        {
                                            isBusy ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                :
                                                <p>Continue</p>
                                        }
                                    </button>
                                </div>
                            </div>

                            {/* right */}
                            <div className='w-full bg-[#F2F2FE] p-5 rounded-[10px] mt-5 lg:mt-0 h-fit'>
                                <p className='text-[16px] font-[700]'>Order Summary</p>

                                <div className="mt-[17px] border-y border-y-gray-300 py-5">
                                    {
                                        cart.map((item: any, i: number) => (
                                            <div key={i} className='flex justify-between'>
                                                <div key={i} className='flex gap-[12px] mb-5'>
                                                    <div>
                                                        <img className='h-[50px] w-[75px] object-cover rounded-[10px]' src={item?.images?.[0]} alt="" />
                                                    </div>
                                                    <div>
                                                        <p className='text-[16px] font-[700] text-deepBlue'>{item?.name || ""}</p>
                                                        <p className='text-[16px] font-[400] text-deepBlue'>
                                                            Amount: <span className='text-defaultBlue'>{item?.countryData?.currencyCode + " " + item?.salePrice}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='text-[16px] font-[700] text-deepBlue'>x{item?.itemQty}</p>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="mt-5">
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[400] text-deepBlue'>Sub Total</p>
                                        <p className='text-[16px] font-[400] text-deepBlue'>GHS {cartTotals?.subTotal.toFixed(2) || 0.00}</p>
                                    </div>

                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[400] text-deepBlue'>Tax</p>
                                        <p className='text-[16px] font-[400] text-deepBlue'>GHS {cartTotals?.tax.toFixed(2) || 0.00}</p>
                                    </div>

                                    <div className='flex justify-between items-center'>
                                        <p className='text-[16px] font-[400] text-deepBlue'>Shipping</p>
                                        <p className='text-[16px] font-[400] text-deepBlue'>GHS {cartTotals?.shipping.toFixed(2) || 0.00}</p>
                                    </div>

                                    <div className='mt-5 flex justify-center py-[15px] px-[40px] rounded-[40px] h-[60px] w-full bg-[#9999F1] bg-opacity-[20%]'>
                                        <p className='text-[21px] font-[700] text-deepBlue'>Total Order: GHS {cartTotals?.grandTotal.toFixed(2) || 0.00}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckoutPage
