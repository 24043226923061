import React, { useState } from 'react'
import robocentreLogo from "../../images/robocentreLogo.jpg"
import { Link, useNavigate } from 'react-router-dom'
import { IoCartOutline } from "react-icons/io5";
import UserProfile from '../shared/userProfile';
import { Badge } from 'antd';
import { useCart } from '../../context/generalContext';
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import MobileNav from './mobileNav';
import { ACCOUNTS_URL, COOKIES_TOKEN_NAME } from '../../helpers/constants';
import { getCookie } from '../../helpers/utils';


const CustomeNavbar = () => {

    const { cart } = useCart();
    const navigate = useNavigate()
    const token = getCookie(COOKIES_TOKEN_NAME)
    const [reFetch, setReFetch] = useState(false)



    //mobileNav
    const [openMobileNav, setOpenMobileNav] = useState(false)

    //menuList
    const menuList = [
        { name: "Courses", link: "#" },
        { name: "For Schools", link: "#" },
        { name: "For Business", link: "#" },
        { name: "For Instructors", link: "#" },
        { name: "Explore", link: "#" }
    ]

    //handleSignUp
    const handleSignUp = () => {
        window.location.href = `${ACCOUNTS_URL}/signup?appref=${window.location.href}`
    }

    //handleSignIn
    const handleSignIn = () => {
        window.location.href = `${ACCOUNTS_URL}/?appref=${window.location.href}`
    }

    return (
        <>
            <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] py-[16px] flex justify-between items-center bg-white shadow-md w-full text-deepBlue'>
                {/* left */}
                <Link to="/">
                    <img src={robocentreLogo} alt="logo" />
                </Link>

                {/* middle */}
                <div className='lg:flex gap-[21px] items-center hidden'>
                    {
                        menuList.map((item, i) => (
                            <Link key={i} to={item.link}>
                                <p className='text-[16px] font-[500]'>{item.name}</p>
                            </Link>
                        ))
                    }
                </div>

                {/* right */}
                <div className='flex gap-5 items-center'>
                    <div>
                        <button className='mt-2' onClick={() => navigate("/cart")}>
                            <Badge count={cart?.length} showZero overflowCount={9}>
                                <IoCartOutline className='h-6 w-6 text-defaultBlue' />
                            </Badge>
                        </button>
                    </div>

                    <div>
                        {
                            token ?
                                <div>
                                    <UserProfile reFetch={reFetch} />
                                </div>
                                :
                                <div className='2xl:flex xl:flex lg:flex md:flex gap-[16px] hidden'>
                                    <button className='text-defaultBlue' onClick={handleSignIn}>
                                        <p className='text-[16px] font-[600]'>Login</p>
                                    </button>
                                    <button className='px-[16px] py-[6px] bg-defaultBlue text-white rounded-[30px]' onClick={handleSignUp}>
                                        <p className='text-[16px] font-[500]'>Sign up</p>
                                    </button>
                                </div>
                        }
                    </div>

                    {/* mobile menu btn */}
                    <div className='2xl:hidden xl:hidden lg:hidden md:hidden'>
                        <button className='h-8 w-8 text-defaultBlue' onClick={() => setOpenMobileNav(true)}>
                            <HiMiniBars3BottomRight />
                        </button>
                    </div>
                </div>
            </div >


            {/* open mobile nav */}
            {
                openMobileNav && <MobileNav isOpened={openMobileNav} handleClose={() => setOpenMobileNav(false)} menuList={menuList} />
            }

        </>
    )
}

export default CustomeNavbar
