import React, { useEffect, useState } from 'react'
import { DatePicker, Input, message, Select, Spin } from 'antd'
import PhoneInput from "react-phone-number-input";
import { HiPlus } from 'react-icons/hi2';
import { LoadingOutlined } from '@ant-design/icons'
import { useCart } from '../../../../context/generalContext';
import BaseService from '../../../../helpers/baseServices';
import { global_variables } from '../../../../helpers/globalVariables';
import moment from 'moment';


const Account = () => {

    const { userData, getUserData } = useCart()

    const [isBusy, setIsBusy] = useState(false)
    const [fName, setFName] = useState("")
    const [mName, setMName] = useState("")
    const [lName, setLName] = useState("")
    const [theEmail, setTheEmail] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState<any>("")
    const [theGender, setThegender] = useState("")
    const [thePhoneNumber, setThePhoneNumber] = useState("");

    //image
    const [imageUrl, setImageUrl] = useState<any>("")
    const [isUploading, setIsUploading] = useState(false)
    const [newImgUrl, setNewImgUrl] = useState("")


    //onload
    useEffect(() => {
        setFName(userData?.firstName)
        setMName(userData?.middleName)
        setLName(userData?.lastName)
        setTheEmail(userData?.email)
        setThePhoneNumber(userData?.phone)
        setImageUrl(userData?.image)
        setThegender(userData?.gender)
        setDateOfBirth(userData?.dob ? moment(userData?.dob) : null)
        console.log("userData:", userData)
    }, [userData])

    //handleDateChange
    const handleDateChange = (date: any) => {
        if (date) {
            setDateOfBirth(date.format('YYYY-MM-DD'));
        } else {
            setDateOfBirth("")
        }
    };

    //handleImageSelect
    const handleImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files?.[0];
        if (file) {
            const fileType = file?.type.split("/")[1]
            const objUrl = URL.createObjectURL(file)
            setImageUrl(objUrl)
            setIsUploading(true)

            //uploadImage
            console.log(fileType)
            try {
                const imgRes: any = await BaseService.file_upload(fName, file, fileType)
                console.log("imgUploadRes", imgRes?.data)
                setNewImgUrl(imgRes?.data?.url)
                setIsUploading(false)
            } catch (error: any) {
                console.log("imgUploadErr:", error)
                message.error(error?.response?.data?.error || "Error while uploading image. Try again")
                setIsUploading(false)
            }

        }
    }


    //handleUpdateInfo
    const handleUpdateInfo = async () => {
        let updated_data = {
            "firstName": fName,
            "image": newImgUrl ? newImgUrl : userData?.image,
            "lastName": lName,
            "middleName": mName,
            "phone": thePhoneNumber,
            "dob": dateOfBirth,
            "gender": theGender
        }

        setIsBusy(true)

        try {
            const response = await BaseService.put_api(global_variables.update_user_details, updated_data)
            console.log("updateRes:", response?.data)
            message.success(response?.data?.message || "Information updated successfully")
            getUserData()
            setIsBusy(false)
        } catch (error: any) {
            console.log(error)
            message.error(error?.response?.data?.error || "Error while updating information. Try again")
            setIsBusy(false)
        }
    }


    return (
        <div className='w-full'>
            <div className="flex justify-between items-center">
                <p className='text-[30px] font-[600] text-defaultText'>Account</p>

                <button className='px-[24px] h-[40px] rounded-[40px] bg-defaultBlue text-white disabled:cursor-not-allowed' onClick={handleUpdateInfo} disabled={isBusy}>
                    <p className='text-[16px] font-[400]'>Save Changes</p>
                </button>
            </div>

            <Spin spinning={isBusy}>
                <div className="mt-[24px] 2xl:flex xl:flex lg:flex gap-[100px]">
                    {/* left */}
                    <div className='text-defaultText'>
                        <div className='w-[400px] border-b border-b-[#E0E0FB] rounded-[8px]'>
                            <label htmlFor='fName' className='text-[16px] font-[400] ml-2'>First name</label>
                            <Input id="fName" className='w-full h-[40px]' variant="borderless" value={fName} onChange={(e) => setFName(e.target.value)} />
                        </div>

                        <div className='w-[400px] mt-[24px] border-b border-b-[#E0E0FB] rounded-[8px]'>
                            <label htmlFor='mName' className='text-[16px] font-[400] ml-2'>Middle name</label>
                            <Input id="mName" className='w-full h-[40px]' variant="borderless" value={mName} onChange={(e) => setMName(e.target.value)} />
                        </div>

                        <div className='w-[400px] mt-[24px] border-b border-b-[#E0E0FB] rounded-[8px]'>
                            <label htmlFor='lName' className='text-[16px] font-[400] ml-2'>Last name</label>
                            <Input id="lName" className='w-full h-[40px]' variant="borderless" value={lName} onChange={(e) => setLName(e.target.value)} />
                        </div>

                        <div className='w-[400px] mt-[24px] border-b border-b-[#E0E0FB] rounded-[8px]'>
                            <label htmlFor='mail' className='text-[16px] font-[400] ml-2'>Email</label>
                            <Input id="mail" className='w-full h-[40px]' variant="borderless" value={theEmail} onChange={(e) => setTheEmail(e.target.value)} />
                        </div>

                        <div className='w-[400px] mt-[24px] border-b border-b-[#E0E0FB] rounded-[8px]'>
                            <label htmlFor='dob' className='text-[16px] font-[400] ml-2'>Date of Birth</label>
                            <DatePicker id="dob" className='w-full h-[40px]' variant="borderless" value={dateOfBirth} onChange={handleDateChange} />
                        </div>

                        <div className='w-[400px] mt-[24px] border-b border-b-[#E0E0FB] rounded-[8px]'>
                            <label htmlFor='gen' className='text-[16px] font-[400] ml-2'>Gender</label>
                            <Select id="gen" className='w-full h-[40px]' variant="borderless" placeholder="Select"
                                options={[
                                    { label: "Male", value: "M" },
                                    { label: "Female", value: "F" },
                                    { label: "Rather not say", value: "O" }
                                ]}
                                value={theGender}
                                onChange={(selected) => setThegender(selected)}
                                allowClear
                            />
                        </div>

                        <div className='w-[400px] mt-[24px]'>
                            <label htmlFor='phone' className='text-[16px] font-[400] ml-2'>Telephone</label>
                            <PhoneInput
                                id="phone"
                                className="w-full border-b border-b-[#E0E0FB] rounded-[8px] px-2 h-[40px]"
                                defaultCountry="GH"
                                inputStyle={{ width: "100%" }}
                                value={thePhoneNumber}
                                onChange={(phone: any) => setThePhoneNumber(phone)}
                                numberInputProps={{
                                    className: "focus:outline-none",
                                }}
                            />
                        </div>
                    </div>

                    {/* right */}
                    <div className='mt-10 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0'>
                        <label htmlFor="photo" className='relative'>
                            <div className={`h-[180px] w-[180px] border border-[#E0E0FB] rounded-full relative ${isUploading ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                                <img className='h-full w-full rounded-full object-cover' src={imageUrl || "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"} alt="" />

                                {
                                    !isUploading &&
                                    <div className="absolute right-3 bottom-0">
                                        <div className='h-[37px] w-[37px] border-[1.5px] border-white bg-defaultBlue rounded-full flex justify-center items-center'>
                                            <HiPlus className='h-5 w-5 text-white' />
                                        </div>
                                    </div>
                                }
                            </div>

                            {
                                isUploading &&
                                <div className='h-[180px] w-[180px] rounded-full bg-black absolute bottom-0 bg-opacity-[50%]'>
                                    <div className='flex flex-col justify-center items-center h-full'>
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 42, color: '#fff' }} spin />} />
                                        <p className='text-white'>Uploading...</p>
                                    </div>
                                </div>
                            }
                        </label>
                        <input id="photo" type="file" className='hidden' onChange={handleImageSelect} disabled={isUploading} />
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default Account
