export function calculateTotalAmount(items: any) {
  if (items) {
    // Check if the array is empty
    if (items.length === 0) {
      return 0;
    }

    // Sum up the amount properties
    let totalAmount = items.reduce(
      (sum: any, item: any) => sum + item.amount * item?.quantity,
      0
    );

    return totalAmount;
  }
}
