import React from 'react'
import CustomeNavbar from '../../components/layout/customeNavbar'
import { Input } from 'antd'
import BalanceCard from './balanceCard'
import AllTransactions from './transactions/allTransactions'

const MyWallet = () => {

    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* navbar */}
                <CustomeNavbar />

                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='2xl:mx-[200px] lg:mx-[60px] mx-5 my-10 lg:grid lg:grid-cols-3 gap-10 pb-20'>
                        {/* left */}
                        <div className='w-full col-span-2'>
                            {/* card */}
                            <BalanceCard />

                            <hr className='border-gray-300 my-5' />

                            {/* tabs */}
                            <div className="mt-5">
                                <p className='font-semibold text-[24px]'>Wallet Transactions</p>

                                {/* tables */}
                                <div className="mt-5 p-5 rounded-[10px] bg-white shadow-sm border border-gray-300">
                                    <AllTransactions />
                                </div>
                            </div>
                        </div>

                        {/* right */}
                        <div className="w-full mt-5 lg:mt-0">
                            {/* redeem */}
                            <div className="w-full rounded-[10px] border border-gray-300 p-5">
                                <p className='text-[18px] font-semibold mb-2'>Redeem Voucher</p>

                                <Input className='h-[40px] w-full' placeholder='Enter voucher code' />

                                <div className="flex justify-end mt-3">
                                    <button className='px-4 py-1 rounded-[30px] bg-defaultBlue text-white'>
                                        Redeem
                                    </button>
                                </div>
                            </div>

                            {/* achievement */}
                            {/* <div className="w-full rounded-[10px] border border-gray-300 p-5 mt-5">
                                <p className='text-[18px] font-semibold'>Achievement</p>

                                <div className="mt-3 pb-3 border-b border-b-gray-300">
                                    <small className='mb-0'>Spend up to GH¢ 10,000</small>
                                    <Progress className='my-0' percent={35} strokeColor="#6666EA" />
                                    <small className='text-gray-500 mt-0'>Till April 20 - 4 days to go</small>
                                </div>

                                <div className="mt-3 pb-3 border-b border-b-gray-300">
                                    <small className='mb-0'>Buy anything before 8AM</small>
                                    <Progress className='my-0' percent={100} />
                                    <small className='text-gray-500 mt-0'>Till April 20 - 4 days to go</small>
                                </div>

                                <div className="mt-3 pb-3 border-b border-b-gray-300">
                                    <small className='mb-0'>Rate five of our products</small>
                                    <Progress className='my-0' percent={76} />
                                    <small className='text-gray-500 mt-0'>Till April 20 - 4 days to go</small>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyWallet
