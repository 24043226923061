import React, { useEffect, useState } from 'react'
import CustomeNavbar from '../../components/layout/customeNavbar'
import { Progress, Rate, Skeleton, message } from 'antd'
import { IoMdHeartEmpty } from 'react-icons/io'
import { Link, useParams } from 'react-router-dom'
import { FaEnvelope, FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import bigRobo from "../../images/bigRobo.png"
import inTheBox from "../../images/inTheBox.png"
import graphicalProgramming from "../../images/icons/gProgramming.svg"
import adruino from "../../images/icons/adruino.svg"
import infrared from "../../images/icons/infrared.svg"
import bluetooth from "../../images/icons/bluetooth.svg"
import tankWheeler from "../../images/icons/tankWheeler.svg"
import triWheeler from "../../images/icons/triWheeler.svg"
import grabber from "../../images/icons/grabber.svg"
import lightDetector from "../../images/icons/lightDetector.svg"
import codingImg from "../../images/icons/codingImg.svg"
import roboticsImg from "../../images/icons/roboticsimg.svg"
import mingoBot from "../../images/icons/mingoBotWhiteBg.svg"
import CustomFooter from '../../components/layout/customFooter'
import AddedToCartDialog from '../../components/shared/addedToCartDialog'
import ProductImageGrid from '../../components/shared/productImageGrid'
import { ProductPayload } from '../../models/product.model'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import { useCart } from '../../context/generalContext'
import { StorageBox } from '../../helpers/stroage'


const ProductDetails = () => {

    const { id } = useParams()
    const token = StorageBox.getAccessToken()
    const { cart, setCart } = useCart();
    const [isBusy, setIsBusy] = useState(false)
    const [productInfo, setProductInfo] = useState<ProductPayload>()
    const [itemQty, setItemQty] = useState(0)


    //onload
    useEffect(() => {
        const getSingleProduct = async () => {
            setIsBusy(true)

            try {
                const response = await BaseService.get_api(`${global_variables.all_products}?filter={"_id": {"$$_id": ${JSON.stringify(id)}}}`)
                console.log(response)
                setProductInfo(response?.data?.payload?.[0])
                setIsBusy(false)
            } catch (error) {
                console.log("Error fetching product:", error)
            }
        }

        if (id) {
            getSingleProduct()
        }
    }, [id])

    //openAdded to cart
    const [showItemAdded, setShowItemAdded] = useState(false)
    const handleOpenItemAdded = () => {
        setShowItemAdded(true)
    }
    const handleCloseItemAdded = () => {
        setShowItemAdded(false)
    }

    const featuredCourses = Array.from({ length: 3 });

    //handleAddToCart
    const handleAddToCart = () => {
        if (itemQty === 0) {
            message.info("Quantity cannot be zero")
        }
        else {
            const existingItem = cart.find((item: any) => item._id === productInfo?._id);
            console.log(existingItem)
            if (existingItem) {
                message.info("This item is already in your cart.")
            } else {
                const updatedCart: any = [...cart, { ...productInfo, itemQty: itemQty }];
                setCart(updatedCart);
                localStorage.setItem('cartItems', JSON.stringify(updatedCart));
                handleOpenItemAdded()
            }
        }
    }


    return (
        <>
            <div className="h-screen overflow-hidden bg-pageBg">
                {/* navbar */}
                <CustomeNavbar />

                {/* main */}
                <div className="h-full overflow-y-auto">
                    <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] mt-[25px]'>
                        {/* first section */}
                        <div className='xl:grid lg:grid md:grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-[50px] w-full h-full lg:mb-[100px] mb-[20px]'>
                            {/* images group */}
                            <div className='w-full xl:h-[470px] lg:h-[470px] md:h-[370px] h-[370px]'>
                                <ProductImageGrid productImages={productInfo?.images} isBusy={isBusy} />
                            </div>

                            <div className='w-full text-deepBlue mt-2'>
                                {
                                    isBusy ?
                                        <>
                                            <Skeleton active />
                                            <Skeleton className='mt-7' active />
                                            <Skeleton className='mt-7' active />
                                        </>
                                        :
                                        <>
                                            <p className='text-[21px] font-[600]'>{productInfo?.name || ""}</p>

                                            <div className='mt-[20px] flex gap-[10px] items-center'>
                                                <Rate count={5} value={4.5} allowHalf disabled />
                                                <p className='text-[12px] font-[400] text-black'>4.5 Customer review</p>
                                            </div>

                                            <div className='mt-[20px] flex gap-[17px] items-center'>
                                                <p className='text-[21px] font-[600] text-defaultBlue'>{productInfo?.countryData?.currencyCode + " " + productInfo?.salePrice}</p>
                                                <p className='text-[14px] font-[400] text-defaultRed line-through'>{productInfo?.countryData?.currencyCode + " " + productInfo?.originalPrice}</p>
                                            </div>

                                            <div className="mt-[20px]">
                                                <p className='text-[14px] font-[400]'>
                                                    {productInfo?.description}
                                                </p>
                                            </div>

                                            {/* variations */}
                                            {/* <div className="mt-[20px]">
                                                <p className='text-[21px] font-[600]'>Color</p>

                                                <div className='flex gap-[16px] items-center'>
                                                    <div className="h-5 w-5 rounded-full bg-defaultYellow cursor-pointer"></div>
                                                    <div className="h-5 w-5 rounded-full bg-[#994FD0] cursor-pointer"></div>
                                                    <div className="h-5 w-5 rounded-full bg-[#D4579D] cursor-pointer"></div>
                                                    <div className="h-5 w-5 rounded-full bg-[#0000DC] cursor-pointer"></div>
                                                </div>
                                            </div>

                                            <div className="mt-[20px]">
                                                <p className='text-[16px] font-[400]'>Size</p>

                                                <div className='flex gap-[8px] items-center mt-1'>
                                                    {
                                                        ["XS", "S", "M", "L", "XL"].map((item, i) => (
                                                            <button key={i} className='w-[32px] h-[26px] bg-[#E5E5FC] text-deepBlue rounded-[5px] focus:text-white focus:bg-defaultBlue'>
                                                                <p className='text-[12px] font-[400]'>{item}</p>
                                                            </button>
                                                        ))
                                                    }
                                                </div>
                                            </div> */}

                                            <div className="mt-[24px] flex gap-[24px] items-center">
                                                <div className='flex border-[1px] border-[#C2C2F7] h-[40px] w-[120px]'>
                                                    <button className='w-[40px] h-[40px]' onClick={() => {
                                                        itemQty > 0 && setItemQty(itemQty - 1)
                                                    }}>-</button>
                                                    <div className='w-[40px] focus: outline-none border-x-[1px] border-[#C2C2F7] flex items-center justify-center'>
                                                        {itemQty}
                                                    </div>
                                                    <button className='w-[40px] h-[40px]' onClick={() => setItemQty(itemQty + 1)}>+</button>
                                                </div>

                                                <button className='px-[25px] py-[7px] rounded-[30px] bg-defaultBlue text-white' onClick={handleAddToCart}>
                                                    <p className='text-[16px] font-[400]'>Add to cart</p>
                                                </button>

                                                {
                                                    token && <button className='text-defaultBlue h-[35px] w-[35px] rounded-full flex justify-center items-center hover:bg-defaultBlue hover:text-white'>
                                                        <IoMdHeartEmpty className='w-[24px] h-[24px]' />
                                                    </button>
                                                }
                                            </div>

                                            <div className="mt-[24px]">
                                                <p className='tetx-[13px] font-[700] text-[#3A393E]'>Share:</p>
                                                <div className="flex gap-[16px] mt-[18px]">
                                                    <Link to="#" className='h-[30px] w-[30px] bg-deepBlue text-white rounded-full flex justify-center items-center'>
                                                        <FaTwitter className='h-4 w-4' />
                                                    </Link>
                                                    <Link to="#" className='h-[30px] w-[30px] bg-deepBlue text-white rounded-full flex justify-center items-center'>
                                                        <FaFacebookF className='h-4 w-4' />
                                                    </Link>
                                                    <Link to="#" className='h-[30px] w-[30px] bg-deepBlue text-white rounded-full flex justify-center items-center'>
                                                        <FaEnvelope className='h-4 w-4' />
                                                    </Link>
                                                    <Link to="#" className='h-[30px] w-[30px] bg-deepBlue text-white rounded-full flex justify-center items-center'>
                                                        <FaLinkedinIn className='h-4 w-4' />
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>

                        {/* video section */}
                        <div className="w-full lg:mb-[85px] mb-[20px]">
                            <img className='w-full object-cover' src={bigRobo} alt="robo" />
                        </div>
                    </div>

                    {/* what's in the box */}
                    <div className='bg-white w-full'>
                        <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] pt-[50px] py-[100px] xl:flex lg:flex md:flex xl:gap-[90px] lg:gap-[90px] md:gap-[50px] items-center'>
                            <div>
                                <img src={inTheBox} alt="box" />
                            </div>

                            <div>
                                <p className='xl:text-[42px] lg:text-[42px] md:text-[22px] text-[22px] font-[700] text-black'>What's in the box</p>

                                <p className='text-4 mt-[12px] font-[400] text-black'>
                                    - Mingo chasis <br />
                                    - DC Motor <br />
                                    - Robot wheel <br />
                                    - Castor wheel <br />
                                    - Robot tracks <br />
                                    - IR Remote controller <br />
                                    - Screw driver <br />
                                    - GoCore controller <br />
                                    - Servo motors <br />
                                    - Grabber <br />
                                    - 4x AA Battery pack <br />
                                    - Track guide <br />
                                    - Track gears <br />
                                    - USB serial cable <br />
                                    - ultrasonic sensor <br />
                                    - Light sensor <br />
                                    - line sensor
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* amazing features */}
                    <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] py-[90px] flex justify-center'>
                        <div>
                            <p className='text-[42px] font-[700] text-black text-center'>Mingo's Amazing Features</p>

                            <div className="mt-[80px] xl:flex lg:flex md:flex xl:gap-[50px] lg:gap-[50px] md:gap-[30px]">
                                <div>
                                    <img className='flex mr-auto ml-auto' src={graphicalProgramming} alt="gr" />
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[40px]'>Graphical Programming</p>
                                </div>
                                <div>
                                    <img className='flex mr-auto ml-auto' src={adruino} alt="ar" />
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[40px]'>Arduino C++ Language</p>
                                </div>
                                <div>
                                    <img className='flex mr-auto ml-auto' src={infrared} alt="ir" />
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[40px]'>Infrared Remote Control</p>
                                </div>
                                <div>
                                    <img className='flex mr-auto ml-auto' src={bluetooth} alt="bt" />
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[40px]'>Bluetooth Control</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* same buddy */}
                    <div className='bg-white w-full text-deepBlue pt-[50px] pb-[100px]'>
                        <div className='lg:px-[100px] px-5'>
                            <p className='text-[42px] font-[700] flex mr-auto ml-auto text-center lg:w-[730px] leading-[42px]'>Same buddy, different builds, unlimited possibilities</p>

                            <p className='text-[21px] font-[400] text-center mt-[30px]'>Unlock more fun with awesome Add-on Pack</p>

                            <div className="mt-[93px] xl:flex lg:flex md:flex justify-center items-center gap-[13px]">
                                <div>
                                    <div className='rounded-[20px] bg-gradient-to-b from-blue-300 to-blue-500 flex justify-center items-center py-[64px] px-[40px]'>
                                        <img src={tankWheeler} alt="tank" />
                                    </div>
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[20px]'>Tank Wheeler</p>
                                </div>

                                <div>
                                    <div className='rounded-[20px] bg-gradient-to-b from-yellow-300 to-yellow-500 flex justify-center items-center py-[64px] px-[40px]'>
                                        <img src={triWheeler} alt="tri" />
                                    </div>
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[20px]'>Tri-Wheeler</p>
                                </div>

                                <div>
                                    <div className='rounded-[20px] bg-gradient-to-b from-teal-200 to-defaultGreen flex justify-center items-center py-[64px] px-[40px]'>
                                        <img src={grabber} alt="grab" />
                                    </div>
                                    <p className='text-[21px] font-[600] text-deepBlue text-center mt-[20px]'>Grabber</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* advanced technology */}
                    <div className='px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] py-[100px] flex justify-center'>
                        <div>
                            <p className='text-[42px] font-[700] text-black text-center'>
                                Built with the best of advanced <br /> technology
                            </p>

                            <div className="mt-[50px] xl:flex lg:flex md:flex   gap-[17px]">
                                <div className='flex flex-col'>
                                    <img src={lightDetector} alt="dect" />
                                    <p className='text-[21px] font-[600] text-deepBlue mt-[20px]'>Tank Wheeler</p>
                                    <p className='text-[16px] font-[400] text-deepBlue mt-[20px] lg:w-[240px]'>
                                        Your robot is smart enough to move towards a light source by programming it to do exactly that
                                    </p>
                                </div>
                                <div className='flex flex-col'>
                                    <img src={lightDetector} alt="dect" />
                                    <p className='text-[21px] font-[600] text-deepBlue mt-[20px]'>Tank Wheeler</p>
                                    <p className='text-[16px] font-[400] text-deepBlue mt-[20px] lg:w-[240px]'>
                                        Your robot is smart enough to move towards a light source by programming it to do exactly that
                                    </p>
                                </div>
                                <div className='flex flex-col'>
                                    <img src={lightDetector} alt="dect" />
                                    <p className='text-[21px] font-[600] text-deepBlue mt-[20px]'>Tank Wheeler</p>
                                    <p className='text-[16px] font-[400] text-deepBlue mt-[20px] lg:w-[240px]'>
                                        Your robot is smart enough to move towards a light source by programming it to do exactly that
                                    </p>
                                </div>
                                <div className='flex flex-col'>
                                    <img src={lightDetector} alt="dect" />
                                    <p className='text-[21px] font-[600] text-deepBlue mt-[20px]'>Tank Wheeler</p>
                                    <p className='text-[16px] font-[400] text-deepBlue mt-[20px] lg:w-[240px]'>
                                        Your robot is smart enough to move towards a light source by programming it to do exactly that
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* mingo for coding */}
                    <div className='bg-white w-full text-deepBlue py-[80px] xl:flex lg:flex md:flex justify-center items-center 2xl:gap-[100px] xl:gap-[100px] lg:gap-[100px] md:gap-[30px] md:px-[100px]'>
                        <div>
                            <img className='flex mr-auto ml-auto' src={codingImg} alt="code" />
                        </div>

                        <div className='flex flex-col justify-center px-5'>
                            <p className='text-[42px] font-[700] text-black'>Mingo for Coding</p>
                            <p className='text-[16px] font-[400] text-deepBlue mt-[12px] lg:w-[320px]'>
                                A free application provides free instructions for starting, configuring and programming your robot.
                            </p>
                        </div>
                    </div>

                    {/* mingo for robotics */}
                    <div className='w-full text-deepBlue py-[80px] xl:flex lg:flex md:flex justify-center items-center 2xl:gap-[100px] xl:gap-[100px] lg:gap-[100px] md:gap-[30px] md:px-[100px]'>
                        <div className='flex flex-col justify-center'>
                            <p className='text-[42px] font-[700] text-black'>Mingo for Robotics</p>
                            <p className='text-[16px] font-[400] text-deepBlue mt-[12px] lg:w-[320px]'>
                                Learners build their own mingo robot from scratch,
                                and with the combination of the power of coding,
                                they are able to unlock their knowledge and
                                unleashing their inner engineering Prowess.
                            </p>
                        </div>

                        <div>
                            <img className='flex mr-auto ml-auto' src={roboticsImg} alt="code" />
                        </div>
                    </div>

                    {/* mingo for stem */}
                    <div className='bg-white w-full text-deepBlue py-[80px] xl:flex lg:flex md:flex justify-center items-center 2xl:gap-[100px] xl:gap-[100px] lg:gap-[100px] md:gap-[30px] md:px-[100px]'>
                        <div>
                            <img className='flex mr-auto ml-auto' src={codingImg} alt="code" />
                        </div>

                        <div className='flex flex-col justify-center'>
                            <p className='text-[42px] font-[700] text-black'>Mingo for STEM</p>
                            <p className='text-[16px] font-[400] text-deepBlue mt-[12px] lg:w-[320px]'>
                                By engaging in creativity, arts, play, do-it-yourself
                                projects with mingo, learners gain the skills, knowledge,
                                confidence and self-efficacy necessary for a successful
                                career in STEM.
                            </p>
                        </div>
                    </div>

                    {/* ratings */}
                    <div className='w-full text-deepBlue py-[80px] px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px]'>
                        <p className='text-[32px] font-400 text-defaultBlue'>Rating</p>

                        <div className='mt-5 xl:grid lg:grid md:grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-[100px] lg:gap-[100px] md:gap-[50px] w-full'>
                            <div className='w-full'>
                                <div className='flex justify-end gap-[25px]'>
                                    <p className='text-[52px] font-[600] text-defaultBlue'>4.9</p>
                                    <div>
                                        <Rate count={5} value={5} disabled />
                                        <p className='mt-[6px] text-[16px] font-[400]'>35456 reviews</p>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    {/* 5 stars */}
                                    <div className="grid grid-cols-3 w-full">
                                        <div className='w-full col-span-1'>
                                            <Rate disabled />
                                        </div>

                                        <div className='w-full col-span-2'>
                                            <div className='flex gap-[8px] items-center w-full'>
                                                <p className='text-[16px] font-[400]'>5</p>
                                                <div className='w-full'>
                                                    <Progress percent={85} strokeColor="#FFC400" status="active" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 4 stars */}
                                    <div className="grid grid-cols-3 w-full mt-3">
                                        <div className='w-full col-span-1'>
                                            <Rate count={4} disabled />
                                        </div>

                                        <div className='w-full col-span-2'>
                                            <div className='flex gap-[8px] items-center w-full'>
                                                <p className='text-[16px] font-[400]'>4</p>
                                                <div className='w-full'>
                                                    <Progress percent={65} strokeColor="#FFC400" status="active" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 3 stars */}
                                    <div className="grid grid-cols-3 w-full mt-3">
                                        <div className='w-full col-span-1'>
                                            <Rate count={3} disabled />
                                        </div>

                                        <div className='w-full col-span-2'>
                                            <div className='flex gap-[8px] items-center w-full'>
                                                <p className='text-[16px] font-[400]'>3</p>
                                                <div className='w-full'>
                                                    <Progress percent={35} strokeColor="#FFC400" status="active" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 2 stars */}
                                    <div className="grid grid-cols-3 w-full mt-3">
                                        <div className='w-full col-span-1'>
                                            <Rate count={2} disabled />
                                        </div>

                                        <div className='w-full col-span-2'>
                                            <div className='flex gap-[8px] items-center w-full'>
                                                <p className='text-[16px] font-[400]'>2</p>
                                                <div className='w-full'>
                                                    <Progress percent={12} strokeColor="#FFC400" status="active" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 1 star */}
                                    <div className="grid grid-cols-3 w-full mt-3">
                                        <div className='w-full col-span-1'>
                                            <Rate count={1} disabled />
                                        </div>

                                        <div className='w-full col-span-2'>
                                            <div className='flex gap-[8px] items-center w-full'>
                                                <p className='text-[16px] font-[400]'>1</p>
                                                <div className='w-full'>
                                                    <Progress percent={0} strokeColor="#FFC400" status="active" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center mt-5">
                                        <button>
                                            <p className='text-[16px] font-[700]'>
                                                See all reviews
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full mt-5 lg:mt-0'>
                                <p className='text-[21px] font-[400] text-black'>TOP REVIEWS FROM MACHINE LEARNING</p>

                                <div className="mt-[25px] text-deepBlue">
                                    {
                                        ["", ""].map((item, i) => (
                                            <div key={i} className='mb-[18px]'>
                                                <p className='text-[16px] font-[400]'>by EJ Mar 27, 2018</p>

                                                <Rate className='mt-2' count={5} value={3.5} allowHalf disabled />

                                                <p className='text-[16px] font-[400] mt-[10px]'>
                                                    Very well structured and delivered course. Progressive introduction of
                                                    concepts and intuitive description by Andrew really give a sense of
                                                    understanding even for the more complex area of the training.
                                                </p>
                                            </div>
                                        ))
                                    }

                                    <button className='px-4 h-[40px] bg-white shadow-sm rounded-[30px] mt-3 border hover:border hover:border-deepBlue'>
                                        Write review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* mingo product */}
                    <div className='w-full bg-white lg:py-[80px] py-10 px-5 md:px-10 lg:px-[93px] xl:px-[93px]'>
                        <div className='shadow-lg bg-[#903EE4] w-full rounded-[30px] xl:grid lg:grid md:grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-[80px] p-5'>
                            <div className='w-full'>
                                <img src={mingoBot} alt="bot" />
                            </div>

                            <div className='w-full text-white lg:pr-[100px] flex flex-col justify-center'>
                                <p className='text-[42px] font-[700]'>Mingo</p>
                                <p className='text-[24px] font-[600] mt-[10px] leading-[30px]'>
                                    Start your Coding journey!
                                    Get your perfect tool to
                                    explore Coding, Electronics,
                                    Robotics and STEAM
                                </p>

                                <button className='mt-[54px] bg-defaultYellow rounded-[30px] px-[60px] py-[15px] w-fit'>
                                    <p className='text-[16px] font-[700]'>Add to cart</p>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* featured courses */}
                    <div className='pt-[70px] pb-[100px]'>
                        <div className='lg:px-[100px] px-5'>
                            <p className='text-[42px] font-[700] text-center leading-[42px]'>Explore our featured Courses with <br /> mingo </p>

                            <div className="mt-[35px] xl:flex lg:flex md:flex justify-center items-center gap-[13px]">
                                {
                                    featuredCourses.map((item, i) => (
                                        <div key={i} className='rounded-[20px] bg-white shadow-lg p-[13px]'>

                                            <img className='w-full h-[192px] object-cover rounded-[20px]' src="https://ichef.bbci.co.uk/news/976/cpsprodpb/E2F0/production/_102969085_robotchild4.gif" alt="course" />


                                            <div className="mt-5">
                                                <div className='bg-opacity-[10%] bg-[#903EE4] w-fit py-[2px] px-[15px] rounded-[30px]'>
                                                    <p className='text-[16px] font-[400] text-deepBlue'>Electronics</p>
                                                </div>

                                                <p className='mt-[18px] font-[600] text-[21px] text-deepBlue mb-5'>
                                                    Lorem ipsum dolor sit amet,consectetur.
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="mt-[66px] flex justify-center">
                                <button className='px-[50px] w-fit border border-defaultBlue text-defaultBlue rounded-[30px] h-[40px]'>
                                    <p className='text-[16px] font-[700]'>See more</p>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* faq */}
                    {/* <div>
                        <FaqsComponent />
                    </div> */}

                    {/* join 5000 */}
                    <div className='lg:py-[60px] py-10 px-5 md:px-10 lg:px-[93px] xl:px-[93px] 2xl:px-[180px] bg-defaultBlue 2xl:grid xl:grid lg:grid md:grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2'>
                        <div className="w-full">
                            <p className='text-[48px] font-[700] leading-[48px]  text-white'>Join 5,000+ learners from across the Globe</p>
                        </div>

                        <div className="w-full flex justify-center items-center">
                            <button className='bg-defaultYellow text-white rounded-[30px] px-[60px] py-[15px] w-fit'>
                                <p className='text-[16px] font-[700]'>Sign up</p>
                            </button>
                        </div>
                    </div>

                    {/* footer */}
                    <div>
                        <CustomFooter />
                    </div>
                </div>
            </div>

            {/* trigger added to cart */}
            {
                showItemAdded && <AddedToCartDialog isOpened={showItemAdded} handleClose={handleCloseItemAdded} product={productInfo} />
            }
        </>
    )
}

export default ProductDetails
