import React from 'react'
import mingoLogo from "../../images/mingoLogo.png"
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa'

const CustomFooter = () => {
    return (
        <>
            <div className='md:px-10 2xl:px-[180px] lg:px-[93px] xl:px-[93px] px-5 2xl:pt-[180px] xl:pt-[180px] lg:pt-[180px] md:pt-[100px] pt-[250px] bg-deepBlue text-white w-full'>
                <div className="xl:grid lg:grid md:grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3">
                    <div className='w-full xl:mb-0 lg:mb-0 md:mb-10 mb-5'>
                        <img src={mingoLogo} alt="mingo" />
                    </div>

                    <div className='xl:mb-0 lg:mb-0 md:mb-10 mb-5'>
                        <p className='text-[21px] font-[600]'>Company</p>

                        <div className="mt-[40px] text-white flex flex-col gap-[24px]">
                            <Link to="#" className='text-[16px] font-[400]'>About</Link>
                            <Link to="#" className='text-[16px] font-[400]'>Vision</Link>
                            <Link to="#" className='text-[16px] font-[400]'>Mission</Link>
                        </div>
                    </div>

                    <div className='xl:mb-0 lg:mb-0 md:mb-10 mb-5'>
                        <p className='text-[21px] font-[600]'>Products</p>

                        <div className="mt-[40px] text-white flex flex-col gap-[24px]">
                            <Link to="#" className='text-[16px] font-[400]'>Mingo Robot</Link>
                            <Link to="#" className='text-[16px] font-[400]'>Gblox</Link>
                        </div>
                    </div>

                    <div>
                        <p className='text-[21px] font-[600]'>Resources</p>

                        <div className="mt-[40px] text-white flex flex-col gap-[24px]">
                            <Link to="#" className='text-[16px] font-[400]'>News</Link>
                            <Link to="#" className='text-[16px] font-[400]'>Blog</Link>
                            <Link to="#" className='text-[16px] font-[400]'>Videos</Link>
                        </div>
                    </div>

                    {/* <div>
                        <p className='text-[21px] font-[600]'>Support</p>

                        <div className="mt-[40px] text-white flex flex-col gap-[24px]">
                            <Link to="#" className='text-[16px] font-[400]'>Hardware</Link>
                            <Link to="#" className='text-[16px] font-[400]'>Software</Link>
                        </div>
                    </div> */}

                    <div className='w-full'>
                        <div className='flex gap-[10px] w-full'>
                            <p className='text-[21px] font-[600]'>Address:</p>
                            <p className='text-[16px] font-[400]'>Presidential Floor, Mövenpick Hotel, Accra, Ghana</p>
                        </div>

                        <div className='flex gap-[10px] w-full mt-[24px]'>
                            <p className='text-[21px] font-[600]'>Contact:</p>
                            <p className='text-[16px] font-[400]'>+233 (0) 244 446 002</p>
                        </div>

                        <div className='flex gap-[10px] items-center w-full mt-[24px]'>
                            <p className='text-[21px] font-[600]'>Email:</p>
                            <p className='text-[16px] font-[400]'>info@mingoblox.com</p>
                        </div>

                        <div className="flex gap-[16px] mt-[23px]">
                            <Link to="#" className='h-[30px] w-[30px] bg-white text-deepBlue rounded-full flex justify-center items-center'>
                                <FaTwitter className='h-4 w-4' />
                            </Link>
                            <Link to="#" className='h-[30px] w-[30px] bg-white text-deepBlue rounded-full flex justify-center items-center'>
                                <FaFacebookF className='h-4 w-4' />
                            </Link>
                            <Link to="#" className='h-[30px] w-[30px] bg-white text-deepBlue rounded-full flex justify-center items-center'>
                                <FaInstagram className='h-4 w-4' />
                            </Link>
                            <Link to="#" className='h-[30px] w-[30px] bg-white text-deepBlue rounded-full flex justify-center items-center'>
                                <FaLinkedinIn className='h-4 w-4' />
                            </Link>
                            <Link to="#" className='h-[30px] w-[30px] bg-white text-deepBlue rounded-full flex justify-center items-center'>
                                <FaYoutube className='h-4 w-4' />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='mt-[90px] mb-5 2xl:flex xl:flex lg:flex md:flex justify-center items-center text-white gap-[100px] pb-20'>
                    <p className='text-[16px] font-[400]'>© 2022 Mingo Blox. All Rights Reserved.</p>

                    <div className='my-5 lg:my-0'>
                        <Link to="#" className='text-[16px] font-[400]'>Privacy Policy</Link>
                    </div>

                    <div>
                        <Link to="#" className='text-[16px] font-[400]'>Terms of Service</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomFooter
