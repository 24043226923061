import React, { createContext, useContext, useState, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';
import { getCookie, setCookieJson } from '../helpers/utils';
import { COOKIES_TOKEN_NAME, COOKIES_USER_DATA } from '../helpers/constants';

// Create a new context for the cart
const CartContext = createContext<any>(null);

// Create a provider component for the cart
export const GeneralContextProvider = ({ children }: any) => {
    const [cart, setCart] = useState([]);
    const [userData, setUserData] = useState<any>(null)
    const [allNewProducts, setAllNewProducts] = useState([])
    const token = getCookie(COOKIES_TOKEN_NAME)
    const [isFetching, setIsFetching] = useState(true)

    //getUserData
    const getUserData = async () => {
        try {
            const response = await BaseService.get_api(global_variables.get_user_details)
            console.log("userDataRes:", response?.data)
            setUserData(response?.data?.payload)
            setCookieJson(COOKIES_USER_DATA, response?.data?.payload)
        } catch (error) {
            console.log("userDataErr:", error)
        }
    }

    //getNewProducts
    const getNewProducts = async () => {
        setIsFetching(true)
        if (token) {
            try {
                const response = await BaseService.get_api(`${global_variables.private_products}?limit=9`)
                // console.log(response?.data)
                setAllNewProducts(response?.data?.payload)
                setIsFetching(false)
            } catch (error) {
                console.log("Error fetching new products:", error)
            }
        } else {
            try {
                const response = await BaseService.get_api(`${global_variables.all_products}?limit=9`)
                // console.log(response?.data)
                setIsFetching(false)
                setAllNewProducts(response?.data?.payload)
            } catch (error) {
                console.log("Error fetching new products:", error)
            }
        }
    }

    // Load cart items from local storage on component mount
    useEffect(() => {
        getUserData()
        getNewProducts()

        const savedCart = localStorage.getItem('cartItems');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }

        // eslint-disable-next-line
    }, []);


    return (
        <CartContext.Provider value={{ cart, setCart, userData, setUserData, getUserData, isFetching, allNewProducts, getNewProducts }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to consume the cart context
export const useCart = () => useContext(CartContext);
